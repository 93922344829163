import React from 'react';

import type { WidgetPlugin } from '../../../types/widgetPlugins';

import s from './pluginsPanel.scss';
import { PluginFallbackLogo } from './svg/pluginFallbackLogo';

interface PluginLogoProps {
  plugin: WidgetPlugin;
}

export const PluginLogo: React.FC<PluginLogoProps> = ({ plugin }) => {
  return plugin.logoUrl ? (
    <img
      alt={`${plugin.name} icon`}
      src={plugin.logoUrl}
      className={s.pluginIcon}
    />
  ) : (
    <PluginFallbackLogo />
  );
};
