/* eslint-disable prettier/prettier */
import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  Fragment,
} from 'react';
import {
  SidePanel,
  VerticalTabs,
  Divider,
  Box,
  CounterBadge,
  Loader,
} from '@wix/design-system';
import {
  ErrorState,
  PendingApp,
  MoreApps,
  RecommendedTools,
} from '../components';
import { TRANSLATIONS } from '../../constants/translations';
import { APP_MANAGER_ORIGIN } from '../../constants/constants';
import {
  AUTOMATION_IDS,
  getBusinessAppSidebarTabDataHook,
} from '../../constants/automationIds';
import { BI_EVENTS_IDS } from '../../constants/biEvents';
import { useSiteSetup } from './hooks/useSiteSetup';
import { useRecommendedTools } from './hooks/useRecommendedTools';
import type { RegularAppData } from '../../services/appMarket/appMarket.types';
import type { DealerAppPageData } from '../../services/dealer/dealer.types';
import type { DealerSegmentContent } from '../../services/dealer/dealer.segmentContent';
import styles from './myBusiness.scss';

import { AppManagerDataContext } from '../../providers/AppManagerDataProvider';
import {
  EditorType,
  InstallInitiator,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';
import { getAppVersionToInstall } from '../../services/appMarket/appMarket.utils';
import { BusinessApp } from './BusinessApp/BusinessApp';
import { useBILogger } from '@wix/fe-essentials/react';
import cx from 'classnames';
import { t } from '../../../../essentials/i18n';

import {
  myBusinessTabIsOpen,
  myBusinessActionInsidePanel,
  myBusinessIsOpen,
} from '@wix/bi-logger-editor-x/v2';

interface MyBusinessViewProps {
  apps: RegularAppData[];
  moreApps: RegularAppData[];
  initialSelectedAppId?: string;
  segmentContent?: DealerSegmentContent | null;
  dealerData?: Record<string, DealerAppPageData> | null;
}

const RECOMMENDED_APPS_TAB_ID = 'recommended_apps_tab_id';
const MORE_APPS_TAB_ID = 'more_apps_tab_id';

export const MyBusiness: React.FC<MyBusinessViewProps> = ({
  apps,
  moreApps,
  initialSelectedAppId,
  segmentContent,
  dealerData,
}) => {
  const { api } = useContext(AppManagerDataContext);
  const logger = useBILogger();

  const [selectedTabId, setSelectedTabId] = useState(() => {
    if (initialSelectedAppId) {
      return initialSelectedAppId;
    }
    if (apps.length > 0) {
      return apps[0].id;
    }
    return MORE_APPS_TAB_ID;
  });

  const [siteSetup, { isLoading: isLoadingSetup, error: setupError }] =
    useSiteSetup(
      api.metaSiteInstance,
      api.checkPermissions,
      api.openBusinessManager,
    );

  const [
    recommendedTools,
    { isLoading: isLoadingRecommendations, error: recommendationsError },
  ] = useRecommendedTools(api.metaSiteInstance, api.openBusinessManager);

  const isLoading = isLoadingSetup || isLoadingRecommendations;
  const error = setupError || recommendationsError;

  const translationKeys = useMemo(() => {
    return {
      recommendedAppsTab:
        segmentContent?.promotionalAppsTitle ||
        TRANSLATIONS.MY_BUSINESS.SIDEBAR.RECOMMENDED_APPS_TAB,
      moreAppsTab:
        segmentContent?.moreAppsTitle ||
        TRANSLATIONS.MY_BUSINESS.SIDEBAR.MORE_APPS_TAB,
    };
  }, [segmentContent]);

  const renderSidebar = () => {
    const sidebarTabs = apps.map((app, index) => ({
      id: app.id,
      label: app.name,
      positionId: index,
      showDotIndicator: app.isPending || app.isUpdateAvailable,
      showDivider: index === apps.length - 1,
      dataHook: getBusinessAppSidebarTabDataHook(app.id),
    }));

    const shouldShowRecommendedToolsTab =
      !isLoading && !recommendedTools.isEmpty();

    if (shouldShowRecommendedToolsTab) {
      sidebarTabs.push({
        id: RECOMMENDED_APPS_TAB_ID,
        label: t(translationKeys.recommendedAppsTab),
        positionId: sidebarTabs.length + 1,
        showDotIndicator: false,
        showDivider: false,
        dataHook: AUTOMATION_IDS.MY_BUSINESS.SIDEBAR_RECOMMENDED_TAB,
      });
    }

    sidebarTabs.push({
      id: MORE_APPS_TAB_ID,
      label: t(translationKeys.moreAppsTab),
      positionId: sidebarTabs.length + 1,
      showDotIndicator: false,
      showDivider: false,
      dataHook: AUTOMATION_IDS.MY_BUSINESS.SIDEBAR_MORE_APPS_TAB,
    });

    const getPositionIdByTabId = (tabId: string): number =>
      sidebarTabs.find((tab) => tab.id === tabId)?.positionId || 0;

    const getTabIdByPositionId = (positionId: number): string =>
      sidebarTabs.find((tab) => tab.positionId === positionId)?.id ?? '';

    return (
      <Box
        className={cx(styles.sidebar, {
          [styles.classic]: api.isClassicEditor,
        })}
        direction="vertical"
        paddingTop="SP1"
      >
        <VerticalTabs
          activeTabId={getPositionIdByTabId(selectedTabId)}
          size="small"
          onChange={(positionId) =>
            setSelectedTabId(getTabIdByPositionId(positionId))
          }
        >
          {sidebarTabs.map((tab) => (
            <Fragment key={tab.id}>
              <VerticalTabs.TabItem id={tab.positionId}>
                <Box verticalAlign="middle">
                  {tab.label}
                  {tab.showDotIndicator && (
                    <CounterBadge className={styles.dot} skin="danger" />
                  )}
                </Box>
              </VerticalTabs.TabItem>

              {tab.showDivider && (
                <Box
                  paddingRight="SP5"
                  paddingLeft="SP5"
                  paddingTop="SP2"
                  paddingBottom="SP2"
                >
                  <Divider />
                </Box>
              )}
            </Fragment>
          ))}
        </VerticalTabs>
      </Box>
    );
  };

  const onAddApp = (app: RegularAppData) => {
    api.addApp({
      appDefId: app.id,
      biData: {
        origin: APP_MANAGER_ORIGIN,
      },
      platformOrigin: {
        initiator: InstallInitiator.Editor,
        type: EditorType.Classic,
        info: {
          type: InstallationOriginType.MY_BUSINESS,
        },
      },
      appVersion: getAppVersionToInstall(app),
    });

    logger.log({
      evid: BI_EVENTS_IDS.APP_LIST_ACTION_CLICKED,
      app_id: app.id,
      action_id: 'add_application',
      app_type: 'not_installed',
    });
    logger.report(
      myBusinessActionInsidePanel({
        appId: app.id,
        actionName: 'add_application',
      }),
    );
  };

  const renderContent = () => {
    const selectedApp = apps.find((app) => app.id === selectedTabId);

    if (error && segmentContent) {
      return <ErrorState error={error} segmentContent={segmentContent} />;
    }

    if (isLoading) {
      return (
        <Box width="100%" height="100%" align="center" verticalAlign="middle">
          <Loader />
        </Box>
      );
    }

    if (selectedTabId === RECOMMENDED_APPS_TAB_ID) {
      return <RecommendedTools tools={recommendedTools.getAll()} />;
    }

    if (selectedTabId === MORE_APPS_TAB_ID) {
      return (
        <MoreApps
          apps={moreApps}
          onAddApp={onAddApp}
          onOpenAppMarket={() => {
            api.openAppMarket(APP_MANAGER_ORIGIN);
            logger.log({
              evid: BI_EVENTS_IDS.APP_LIST_ACTION_CLICKED,
              action_id: 'open_app_market',
            });
            logger.report(
              myBusinessActionInsidePanel({
                appId: selectedTabId,
                actionName: 'go_to_market_',
              }),
            );
            logger.log({
              evid: BI_EVENTS_IDS.OPEN_APP_MARKET,
              referral_info: APP_MANAGER_ORIGIN,
            });
          }}
        />
      );
    }

    if (!selectedApp) {
      return null;
    }

    if (selectedApp && selectedApp.isInstalled) {
      return (
        <BusinessApp
          key={selectedApp.id}
          app={selectedApp}
          appSetup={siteSetup.getAppSetup(selectedApp.id)}
          recommendedTools={recommendedTools.getByApp(selectedApp.id)}
          dealerData={dealerData?.[selectedApp.id]}
          appActions={api.getAppActions(selectedApp.id)}
          premiumBannerInfo={api.getAppPremiumBannerInfo(selectedApp.id)}
        />
      );
    }

    if (selectedApp && selectedApp.isPending) {
      return (
        <PendingApp
          onAddApp={() => {
            onAddApp(selectedApp);
            logger.log({
              evid: BI_EVENTS_IDS.APP_LIST_ACTION_CLICKED,
              app_id: selectedApp.id,
              action_id: 'continue_installation',
            });
            logger.report(
              myBusinessActionInsidePanel({
                appId: selectedApp?.id,
                actionName: 'continue_installation',
              }),
            );
          }}
        />
      );
    }

    return null;
  };

  const allAppsInstalled = apps
    ?.filter((app) => app.isInstalled)
    ?.map((app) => app.name)
    ?.join(',');

  const recommendedApps = recommendedTools
    ?.getAll()
    ?.map((app) => app.name)
    ?.join(',')

  useEffect(() => {
    logger.report(
      myBusinessIsOpen({
        origin: 'sidebar',
        appsArray: allAppsInstalled,
        recommendedApps,
      }),
    );
  }, [logger, allAppsInstalled, recommendedApps]);

  useEffect(() => {
    const tabName = apps.find((app) => app.id === selectedTabId)?.name;
    logger.log({
      evid: BI_EVENTS_IDS.TAB_CHANGED,
      tab_name: selectedTabId,
    });
    logger.report(
      myBusinessTabIsOpen({
        tabName,
        appsArray: allAppsInstalled,
        recommendedApps,
      }),
    );
  }, [logger, selectedTabId, allAppsInstalled, recommendedApps, apps]);

  return (
    <div
      className={styles.container}
      data-hook={AUTOMATION_IDS.MY_BUSINESS.CONTAINER}
    >
      { apps.length ? renderSidebar() : null }

      <Divider direction="vertical" />

      <SidePanel width="100%">
        <SidePanel.Content stretchVertically noPadding>
          <div className={styles.content}>{renderContent()}</div>
        </SidePanel.Content>
      </SidePanel>
    </div>
  );
};
