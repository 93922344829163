import React, { type FC, useEffect, useCallback } from 'react';
import cx from 'classnames';
import {
  TableListItem,
  Text,
  TextButton,
  Button,
  Tooltip,
  Box,
  CheckToggle,
} from '@wix/design-system';
import type { TableListItemColumn } from '@wix/design-system';
import {
  AUTOMATION_IDS,
  getBusinessAppSetupStepDataHook,
} from '../../../constants/automationIds';
import { BI_EVENTS_IDS } from '../../../constants/biEvents';
import { usePrevious } from '../../../hooks/usePrevious';
import type { AppSetupStep as AppSetupStepModel } from '../../../services/setup/types';
import { useBILogger } from '@wix/fe-essentials/react';
import { TRANSLATIONS } from '../../../constants/translations';
import styles from './AppSetupStep.scss';
import { t } from '../../../../../essentials/i18n';
import {
  myBusinessAllSetUpActionsAreCompleted,
  myBusinessSetUpActionClick,
} from '@wix/bi-logger-editor-x/v2';

export interface AppSetupStepProps {
  step: AppSetupStepModel;
  order: number;
  isPriority: boolean;
  showDivider: boolean;
}

export const AppSetupStep: FC<AppSetupStepProps> = ({
  step,
  order,
  isPriority,
  showDivider,
}) => {
  const logger = useBILogger();

  const reportStepActionBiEvent = useCallback(
    (actionName: 'main action' | 'skip' | 'undo') => {
      logger.log({
        evid: BI_EVENTS_IDS.SETUP_STEP_ACTION_CLICKED,
        app_id: step.appDefId,
        target: actionName,
        targetName: step.title,
        assetCampaignGuid: step.metadata.campaignId,
        Asset_location_from_group_list: order,
      });
      logger.report(
        myBusinessSetUpActionClick({
          appId: step.appDefId,
          target: actionName,
          targetName: step.title,
        }),
      );
    },
    [logger, step, order],
  );

  const onMainAction = useCallback(() => {
    step.action.execute();
    reportStepActionBiEvent('main action');
  }, [reportStepActionBiEvent, step]);

  const onSkip = useCallback(() => {
    step.skip();
    reportStepActionBiEvent('skip');
  }, [reportStepActionBiEvent, step]);

  const onUnskip = useCallback(() => {
    step.unskip();
    reportStepActionBiEvent('undo');
  }, [reportStepActionBiEvent, step]);

  const getOptions = (): TableListItemColumn[] => {
    switch (step.status) {
      case 'todo':
        return [
          {
            value: (
              <Box verticalAlign="middle" gap="SP2">
                <Box minWidth="18px" className={styles.checkboxContainer}>
                  <CheckToggle size="small" skin="success" disabled />
                </Box>

                <StepTitle title={step.title} />
              </Box>
            ),
            width: 'auto',
            align: 'left',
          },
          {
            value: (
              <TodoStepActions
                step={step}
                isPriority={isPriority}
                onMainAction={onMainAction}
                onSkip={onSkip}
              />
            ),
            align: 'right',
          },
        ];
      case 'skipped':
        return [
          {
            value: (
              <Box verticalAlign="middle" gap="SP2">
                <Box minWidth="18px" className={styles.checkboxContainer}>
                  <CheckToggle size="small" skin="success" disabled />
                </Box>

                <StepTitle title={step.title} isDimmed />
              </Box>
            ),
            width: 'auto',
            align: 'left',
          },
          {
            value: <SkippedStepActions step={step} onUnskip={onUnskip} />,
            align: 'right',
          },
        ];
      case 'completed':
        return [
          {
            value: (
              <Box verticalAlign="middle" gap="SP2">
                <Box minWidth="18px" className={styles.checkboxContainer}>
                  <CheckToggle size="small" skin="success" checked />
                </Box>

                <StepTitle title={step.title} />
              </Box>
            ),
            width: 'auto',
            align: 'left',
          },
        ];
      default:
        throw new Error(`Unexpected setup step status "${step.status}"`);
    }
  };

  const prevStatus = usePrevious(step.status);
  useEffect(() => {
    if (step.status === 'completed' && step.status !== prevStatus) {
      logger.log({
        evid: BI_EVENTS_IDS.SETUP_STEP_COMPLETED,
        app_id: step.appDefId,
        target: 'main action',
        targetName: step.title,
      });
      logger.report(
        myBusinessAllSetUpActionsAreCompleted({
          appId: step.appDefId,
          target: 'main action',
          targetName: step.title,
        }),
      );
    }
  }, [logger, step, prevStatus]);

  useEffect(() => {
    logger.log({
      evid: BI_EVENTS_IDS.SETUP_STEP_LOADED,
      app_id: step.appDefId,
      targetName: step.title,
      assetCampaignGuid: step.metadata.campaignId,
      Asset_location_from_group_list: order,
    });
  }, [logger, step, order]);

  return (
    <TableListItem
      className={styles.container}
      dataHook={getBusinessAppSetupStepDataHook(step.id)}
      verticalPadding="medium"
      showDivider={showDivider}
      options={getOptions()}
    />
  );
};

const StepTitle: FC<{
  title: string;
  isDimmed?: boolean;
  isCrossed?: boolean;
}> = ({ title, isDimmed, isCrossed }) => {
  return (
    <div className={cx(styles.title, { [styles.titleCrossed]: isCrossed })}>
      <Text
        dataHook="setup-step-title-text"
        size="small"
        weight="thin"
        skin={isDimmed ? 'disabled' : 'standard'}
        maxLines={2}
        ellipsis
        secondary
      >
        {t(title)}
      </Text>
    </div>
  );
};

const SkippedStepActions: FC<{
  step: AppSetupStepModel;
  onUnskip: () => void;
}> = ({ step, onUnskip }) => {
  return (
    <Box gap="SP2" verticalAlign="middle">
      <Text size="tiny" weight="thin" skin="disabled">
        {t(TRANSLATIONS.MY_BUSINESS.BUSINESS_APP.LINK)}
      </Text>

      <Tooltip
        disabled={step.isPermitted}
        content={t(TRANSLATIONS.MY_BUSINESS.SETUP_STEP.PERMISSION_TEXT)}
      >
        <TextButton
          size="tiny"
          weight="thin"
          disabled={!step.isPermitted}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_UNSKIP}
          onClick={onUnskip}
        >
          {t(TRANSLATIONS.MY_BUSINESS.BUSINESS_APP.BTN_UNDO)}
        </TextButton>
      </Tooltip>
    </Box>
  );
};

const TodoStepActions: FC<{
  step: AppSetupStepModel;
  isPriority: boolean;
  onMainAction: () => void;
  onSkip: () => void;
}> = ({ step, isPriority, onMainAction, onSkip }) => {
  const allowSkip = !step.isMandatory && step.isPermitted;

  return (
    <Box gap="SP2" verticalAlign="middle">
      {allowSkip && (
        <TextButton
          size="tiny"
          weight="thin"
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_SKIP}
          onClick={onSkip}
        >
          {t(TRANSLATIONS.MY_BUSINESS.BUSINESS_APP.BTN_SKIP)}
        </TextButton>
      )}

      <Tooltip
        disabled={step.isPermitted}
        content={t(TRANSLATIONS.MY_BUSINESS.SETUP_STEP.PERMISSION_TEXT)}
      >
        <Button
          size="tiny"
          priority={!isPriority && step.isPermitted ? 'secondary' : 'primary'}
          disabled={!step.isPermitted}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_MAIN_ACTION}
          onClick={onMainAction}
        >
          {t(step.action.label)}
        </Button>
      </Tooltip>
    </Box>
  );
};
