import { TRANSLATIONS } from '../../constants/translations';
import { AppData, StudioComponent } from '@wix/document-services-types';
import type {
  AppDescriptor,
  CustomActionConfig,
  ExternalLinkActionConfig,
} from '@wix/platform-editor-sdk';
import type {
  AppAction,
  AppActions,
  AppPremiumBannerInfo,
} from './platform.types';
import _ from 'lodash';
import { Delete, Help } from '@wix/wix-ui-icons-common';
import { AUTOMATION_IDS } from '../../constants/automationIds';
import { APP_MANAGER_ORIGIN } from '../../constants/constants';
import { AppManagerApiDependencies } from '../../../../types/appManager';
import { t } from '../../../../essentials/i18n';

type ActionConfig = CustomActionConfig | ExternalLinkActionConfig;

export const GENERAL_ACTION_IDS = {
  LEARN_MORE: 'LEARN_MORE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
} as const;

interface GeneralAction extends AppAction {
  condition: () => boolean;
}

type GetAppGeneralActions = (
  appDefId: string,
  deps: AppManagerApiDependencies,
) => AppAction[];

interface PrivateAppData extends AppData {
  latestVersion: string;
  displayVersion: string;
  blocksVersion?: string;
  canEdit?: boolean;
  isPublished?: boolean;
  components?: StudioComponent[];
}

const getAppGeneralActions: GetAppGeneralActions = (appDefId, deps) => {
  const appDescriptor = deps.getAppDescriptor(appDefId);
  const defaultActions = appDescriptor?.defaultActions || {};

  const config: GeneralAction[] = [
    {
      id: GENERAL_ACTION_IDS.LEARN_MORE,
      condition: () => !!defaultActions.learnMoreKB,
      onClick: () => deps.openHelpCenter(defaultActions.learnMoreKB),
      title: t(
        TRANSLATIONS.APP_PAGE.MORE_ACTIONS_SECTION.ACTIONS_LABELS.LEARN_MORE,
      ),
      symbol: Help,
      dataHook: AUTOMATION_IDS.APP_PAGE.LEARN_MORE_ACTION,
    },
    {
      id: GENERAL_ACTION_IDS.DELETE,
      condition: () => defaultActions.uninstall !== 'HIDE',
      onClick: () => {
        deps.onUninstallApp(appDefId, true);
      },
      title: t(
        TRANSLATIONS.APP_PAGE.MORE_ACTIONS_SECTION.ACTIONS_LABELS.DELETE_APP,
      ),
      symbol: Delete,
      dataHook: AUTOMATION_IDS.APP_PAGE.REMOVE_APP_ACTION,
    },
  ];

  return config.filter((action) => action.condition());
};

export const isDescriptorExists = (
  appDefId: string,
  deps: {
    getAppDescriptor: AppManagerApiDependencies['getAppDescriptor'];
  },
) => {
  const pageDescriptor = deps.getAppDescriptor(appDefId);
  return pageDescriptor
    ? Object.values(pageDescriptor).find((value) => !_.isEmpty(value))
    : false;
};

export const defaultAppActions: AppActions = {
  primary: [],
  general: [],
  secondary: [],
};

export const getAppActions = (
  appDefId: string,
  deps: AppManagerApiDependencies,
): AppActions => {
  const appDescriptor = deps.getAppDescriptor(appDefId);

  if (!appDescriptor) {
    return defaultAppActions;
  }

  const handleActionClick = (
    action: CustomActionConfig | ExternalLinkActionConfig,
  ) => {
    if ('url' in action) {
      window.open(action.url, '_blank');
    } else {
      deps.notifyApplication(appDefId, action.actionId);
    }
  };

  const mapPlatformActionToAppAction = (
    action: CustomActionConfig | ExternalLinkActionConfig,
    dataHook: any,
  ): AppAction => {
    const id = 'actionId' in action ? action.actionId : action.url;

    return {
      id,
      title: action.title,
      iconName: action.icon,
      onClick: () => handleActionClick(action),
      dataHook,
      permissions: deps.addGrantedToPermissions(action.permissions),
    };
  };

  const app: PrivateAppData = {
    ...deps.getAppDataByAppDefId(appDefId),
    latestVersion: deps.getAppLatestVersion(appDefId),
  } as PrivateAppData;

  const updateAction: AppAction[] = deps.shouldShowUpdateAction(app)
    ? [
        {
          id: GENERAL_ACTION_IDS.UPDATE,
          title:
            TRANSLATIONS.APP_PAGE.MORE_ACTIONS_SECTION.ACTIONS_LABELS.UPDATE,
          iconName: 'appManager_settingsAction',
          onClick: () => deps.onUpdateApp(app),
          dataHook: AUTOMATION_IDS.APP_PAGE.UPDATE_ACTION,
        },
      ]
    : [];

  const primaryActions = (appDescriptor.mainActions as ActionConfig[]).map(
    (action) =>
      mapPlatformActionToAppAction(
        action,
        AUTOMATION_IDS.APP_PAGE.PRIMARY_ACTION,
      ),
  );
  const secondaryActions = appDescriptor.customActions.map(
    (action: CustomActionConfig | ExternalLinkActionConfig) =>
      mapPlatformActionToAppAction(
        action,
        AUTOMATION_IDS.APP_PAGE.SECONDARY_ACTION,
      ),
  );

  const generalActions = getAppGeneralActions(appDefId, deps);

  return {
    primary: updateAction.concat(primaryActions),
    secondary: secondaryActions,
    general: generalActions,
  };
};

export const getAppPremiumBannerInfo = (
  appDefId: string,
  deps: AppManagerApiDependencies,
): AppPremiumBannerInfo | null => {
  const appDescriptor = deps.getAppDescriptor(appDefId);

  const upgradeConfig = (appDescriptor as AppDescriptor)?.defaultActions
    ?.upgrade;

  if (!upgradeConfig) {
    return null;
  }

  if (upgradeConfig.upgradeType === 'SITE_UPGRADE' && deps.isPremium()) {
    return null;
  }

  const premiumOrigin = `${APP_MANAGER_ORIGIN}_${appDefId}`;

  const premiumUrl = deps.getPremiumUrl({
    appDefinitionId: appDefId,
    origin: premiumOrigin,
    referralAdditionalInfo: premiumOrigin,
  });

  return {
    upgrade: () => window.open(premiumUrl, '_blank'),
    text: upgradeConfig.upgradeText,
    linkText: upgradeConfig.upgradeLinkText,
  };
};
