import React from 'react';
import { EmptyState, Button, Image, Box } from '@wix/design-system';
import { TRANSLATIONS } from '../../../constants/translations';
import { AUTOMATION_IDS } from '../../../constants/automationIds';
import { SvgPendingAppIcon } from './SvgPendingAppIcon';
import { t } from '../../../../../essentials/i18n';

export interface PendingAppProps {
  onAddApp: () => void;
}

export const PendingApp: React.FC<PendingAppProps> = ({ onAddApp }) => {
  return (
    <Box padding="SP7" align="center">
      <EmptyState
        image={<SvgPendingAppIcon />}
        title={t(TRANSLATIONS.MY_BUSINESS.PENDING_APP.HEADER)}
        subtitle={t(TRANSLATIONS.MY_BUSINESS.PENDING_APP.TEXT)}
      >
        <Button
          dataHook={AUTOMATION_IDS.MY_BUSINESS.PENDING_APP_INSTALL_ACTION}
          size="small"
          onClick={onAddApp}
        >
          {t(TRANSLATIONS.MY_BUSINESS.PENDING_APP.LINK)}
        </Button>
      </EmptyState>
    </Box>
  );
};
