import React from 'react';
import { Box, Card, Text } from '@wix/design-system';
import { StatusCompleteFilledSmall } from '@wix/wix-ui-icons-common';

import { PluginLogo } from './pluginLogo';
import { useWidgetPluginManager } from '../hooks';
import { useTranslation } from '../../useTranslation';
import { WidgetPlugin } from '../../../types/widgetPlugins';

import s from './pluginsPanel.scss';

export const PluginInfo = (props: { plugin: WidgetPlugin }) => {
  const { plugin } = props;
  const { isPluginInstalled } = useWidgetPluginManager();
  const [t] = useTranslation();

  return (
    <Card>
      <Box
        className={s.pluginDetails}
        direction="vertical"
        height="162px"
        dataHook={`widgetPlugins.pluginId:${plugin.id}`}
      >
        <section>
          <Box className={s.pluginIconContainer} marginBottom="SP1">
            <PluginLogo plugin={plugin} />
          </Box>
          <Text weight="bold" size="medium" ellipsis maxLines={2}>
            {plugin.name}
          </Text>
          <Box marginBottom="SP1">
            <Text size="tiny">
              {t('PLATFORM_Widget_Slots_plugin_developer', {
                company_name: plugin.author,
              })}
            </Text>
          </Box>
          <Box>
            <Text size="tiny" ellipsis maxLines={2}>
              {plugin.description}
            </Text>
          </Box>
        </section>

        {isPluginInstalled(plugin) && (
          <Text
            size="tiny"
            skin="success"
            className={s.pluginInstalled}
            dataHook="widgetPlugins.isInstalled"
          >
            <StatusCompleteFilledSmall className={s.pluginInstalledIcon} />
            {t('PLATFORM_Widget_Slots_Modal_Installed')}
          </Text>
        )}
      </Box>
    </Card>
  );
};
