// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QpC05i,.zdtaID{display:flex;width:100%;height:100%}.QpC05i{display:flex;flex-direction:column}.zdtaID{justify-content:center;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `QpC05i`,
	"loaderWrapper": `zdtaID`
};
export default ___CSS_LOADER_EXPORT___;
