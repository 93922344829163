import { dealerService, ESTATES, parseResponseOffers } from './dealer.common';

export type DealerSegmentContent = Partial<{
  leftBarButtonIconName: string;
  leftBarButtonIconNameOldWorkspace: string;
  leftBarButtonName: string;

  panelTitle: string;
  panelHelpId: string;
  headerImageSrc: string;
  installedAppsTitle: string;
  installedAppsTooltipText: string;

  promotionalAppsTitle: string;
  promotionalAppsTooltipText: string;
  moreAppsTitle: string;
  moreAppsLinkText: string;
  errorMessageTitle: string;
}>;

export const loadSegmentContent = async (metaSiteInstance: string) => {
  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: ESTATES.SEGMENT_CONTENT });

  if (!response.offers?.length) {
    return;
  }

  const assets = parseResponseOffers<DealerSegmentContent>(response.offers);

  return assets[0];
};
