// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jyu2aA{position:relative;width:100%;height:100%;overflow:hidden}.Z1ETAk{position:absolute;top:0;left:0;right:0;bottom:0;display:flex;flex-direction:column;align-items:center;justify-content:center}.GGTIo5{margin-top:10px}.Vb8JFo{opacity:0;transform:translateY(100%)}.VSybMg{opacity:1;transform:translateY(0);transition:all 250ms}.qgZwkf{transition-delay:125ms}.MgHAV0{transition-delay:1.25s}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `jyu2aA`,
	"successState": `Z1ETAk`,
	"dismiss": `GGTIo5`,
	"slideFadingIn": `Vb8JFo`,
	"slideFadingInActive": `VSybMg`,
	"delayedTransition": `qgZwkf`,
	"longDelayedTransition": `MgHAV0`
};
export default ___CSS_LOADER_EXPORT___;
