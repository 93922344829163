import { V2App } from '@wix/ambassador-app-service-webapp/http';
import type { RegularAppData } from './appMarket.types';
import { getQueryParams } from '../../../../utils';
import { tpaUtils } from '../../../shared/util';

export const compareAppsByAppManagerPredefinedList =
  (appsList: string[]) =>
  <T extends V2App>(app1: T, app2: T) => {
    const app1Index = appsList.indexOf(app1.id ?? '');
    const app2Index = appsList.indexOf(app2.id ?? '');

    if (app1Index === -1) {
      return 1;
    }
    if (app2Index === -1) {
      return -1;
    }

    return app1Index - app2Index;
  };

export const getAppData =
  (appsList: string[]) =>
  (app?: V2App): Partial<RegularAppData> | undefined => {
    return (
      app && {
        id: app.id,
        name: app.name,
        description: app.market?.marketListing?.basicInfo?.teaser || '',
        icon: app.market?.marketListing?.assetsMap?.appIcon?.assets?.[0]?.url,
        slug: app.market?.slug || '',
        version: app.version,
        isPublished:
          (app.id && appsList.includes(app.id)) || app.status === 'PUBLISHED',
      }
    );
  };

const addCaretPrefixIfNeeded = (str: string) =>
  str.startsWith('^') ? str : `^${str}`;

export const getAppVersionToInstall = (app: RegularAppData) => {
  // There is a case when the editor is opened with the QS of what app version should be installed.
  // In order to support this capability, we need to parse app market params and install a specific version of an app if it was provided
  const queryParams = getQueryParams();
  const appMarketQS = queryParams.appMarketParams;

  if (appMarketQS) {
    const parsedParams = tpaUtils.parseAppMarketQueryParam(appMarketQS);

    if (parsedParams?.appDefId === app.id && parsedParams?.version) {
      return parsedParams.version;
    }
  }

  return addCaretPrefixIfNeeded(app.version);
};
