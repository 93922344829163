import { dealerService, ESTATES } from './dealer.common';
import type { SetupStepDealerData } from './dealer.types';

export const fetchSetupStepsData = async (
  metaSiteInstance: string,
): Promise<SetupStepDealerData[]> => {
  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: ESTATES.EDITOR_SETUP });

  if (!response.offers) {
    return [];
  }

  return response.offers
    .filter((offer) => Boolean(offer?.asset?.payloadJson))
    .map((offer) => ({
      ...JSON.parse(offer.asset?.payloadJson ?? ''),
      metadata: {
        offerId: offer.offerGuid,
        offerName: offer.offerName,
        campaignId: offer.campaignId,
      },
      dynamicParams: offer.asset?.dynamicParams ?? {},
    }));
};
