import React, { useMemo } from 'react';
import { AppManagerPrivateAPI } from '../appManagerAPI';
import { INTERACTIONS } from '../constants/constants';
import { DealerSegmentApp } from '../services/dealer/dealer.segmentApps';
import { DealerAppPageData } from '../services/dealer/dealer.types';
import { RegularAppData } from '../services/appMarket/appMarket.types';
import {
  DealerSegmentContent,
  loadSegmentContent,
} from '../services/dealer/dealer.segmentContent';
import { UseRequestResponse, useRequest } from '../hooks/useRequest';

interface AppManagerData {
  segmentApps: UseRequestResponse<DealerSegmentApp[]>;
  dealerData: UseRequestResponse<Record<string, DealerAppPageData>>;
  allApps: UseRequestResponse<Partial<RegularAppData>[]>;
  segmentContent: UseRequestResponse<DealerSegmentContent | undefined>;
}

export interface IAppManagerDataContextInput {
  children?: React.ReactNode;
  api: AppManagerPrivateAPI;
}

export interface IAppManagerDataContextValue {
  api: AppManagerPrivateAPI;
  data: AppManagerData;
}

export const AppManagerDataContext =
  React.createContext<IAppManagerDataContextValue>(
    {} as IAppManagerDataContextValue,
  );

export const AppManagerDataProvider: React.FC<IAppManagerDataContextInput> = ({
  children,
  api,
}) => {
  const segmentApps = useRequest(() => api.loadAndTransformSegmentApps(), {
    fedopsInteractionName: INTERACTIONS.APP_MANAGER.FETCH_DEALER_SEGMENT_APPS,
    initialData: [],
  });

  const dealerData = useRequest(() => api.loadAndTransformAppPageOverrides(), {
    fedopsInteractionName: INTERACTIONS.APP_MANAGER.FETCH_DEALER_INFO,
    initialData: {},
  });

  const allApps = useRequest(() => api.loadApps(), {
    fedopsInteractionName: INTERACTIONS.APP_MANAGER.FETCH_WIX_APPS,
    initialData: [],
  });

  const segmentContent = useRequest(
    () => loadSegmentContent(api.metaSiteInstance),
    {
      fedopsInteractionName:
        INTERACTIONS.APP_MANAGER.FETCH_DEALER_SEGMENT_CONTENT,
      initialData: {},
    },
  );

  const data: AppManagerData = useMemo(
    () => ({
      segmentApps,
      dealerData,
      allApps,
      segmentContent,
    }),
    [segmentApps, dealerData, allApps, segmentContent],
  );

  const appManagerData = useMemo(
    () => ({
      api,
      data,
    }),
    [data, api],
  );

  return (
    <AppManagerDataContext.Provider value={appManagerData}>
      {children}
    </AppManagerDataContext.Provider>
  );
};
