import { dealerService, ESTATES } from './dealer.common';
import type { RecommendedToolDealerData } from './dealer.types';

export const fetchRecommendedToolsData = async (
  metaSiteInstance: string,
): Promise<RecommendedToolDealerData[]> => {
  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: ESTATES.RECOMMENDED_TOOLS });

  if (!response.offers) {
    return [];
  }

  return response.offers
    .filter((offer) => Boolean(offer?.asset?.payloadJson))
    .map((offer) => ({
      ...(offer.asset?.payloadJson && JSON.parse(offer.asset?.payloadJson)),
      metadata: {
        offerId: offer.offerGuid,
        offerName: offer.offerName,
        campaignId: offer.campaignId,
      },
    }));
};
