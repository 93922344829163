import React, { useContext } from 'react';
import { EmptyState, TextButton, Box } from '@wix/design-system';
import { TRANSLATIONS } from '../../../constants/translations';
import { AUTOMATION_IDS } from '../../../constants/automationIds';
import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import type { DealerSegmentContent } from '../../../services/dealer/dealer.segmentContent';
import { AppManagerDataContext } from '../../../providers/AppManagerDataProvider';
import { SvgErrorAppIcon } from './SvgErrorAppIcon';
import { t } from '../../../../../essentials/i18n';

export interface ErrorStateProps {
  segmentContent?: DealerSegmentContent | null;
  error: AmbassadorHTTPError<any> | unknown;
}

export const ErrorState: React.FC<ErrorStateProps> = ({
  segmentContent,
  error,
}) => {
  const { api } = useContext(AppManagerDataContext);

  const getTranslationKeys = () => {
    if ((error as AmbassadorHTTPError).httpStatus === -1) {
      return TRANSLATIONS.ERROR_STATE.CONNECTION_ISSUES;
    }

    return {
      ...TRANSLATIONS.ERROR_STATE.GENERAL,
      TITLE:
        segmentContent?.errorMessageTitle ||
        TRANSLATIONS.ERROR_STATE.GENERAL.TITLE,
    };
  };

  const TRANSLATION_KEYS = getTranslationKeys();

  const openHelpCenter = () =>
    api.openHelpCenter(segmentContent?.panelHelpId, {
      learn_more: true,
    });

  return (
    <Box padding="SP7" align="center">
      <EmptyState
        dataHook={AUTOMATION_IDS.ERROR_STATE_CONTAINER}
        image={<SvgErrorAppIcon />}
        title={t(TRANSLATION_KEYS.TITLE)}
        subtitle={t(TRANSLATION_KEYS.TEXT)}
      >
        <TextButton size="small" onClick={openHelpCenter}>
          {t(TRANSLATIONS.ERROR_STATE.LINK)}
        </TextButton>
      </EmptyState>
    </Box>
  );
};
