import React, {
  FC,
  ReactNode,
  Suspense,
  lazy,
  useEffect,
  useState,
} from 'react';

import type { i18n as i18nType } from '@wix/wix-i18n-config';

const I18nextProvider = lazy(() =>
  import('@wix/wix-i18n-config').then((module) => ({
    default: module.I18nextProvider,
  })),
);

interface EditorPlatformI18nProviderProps {
  locale?: string;
  children: ReactNode;
}

export const EditorPlatformI18nProvider: FC<
  EditorPlatformI18nProviderProps
> = ({ locale = 'en', children }) => {
  const [i18n, setI18n] = useState<i18nType | null>(null);

  useEffect(() => {
    import('../../essentials/i18n').then(async ({ initialize }) => {
      const i18nInstance = await initialize({ locale, wait: true });
      setI18n(i18nInstance);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (i18n && i18n.language !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, locale]);

  return (
    <Suspense fallback="">
      {i18n ? <I18nextProvider i18n={i18n}>{children}</I18nextProvider> : null}
    </Suspense>
  );
};
