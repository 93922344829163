import React from 'react';
import {
  TableListItem,
  Box,
  Text,
  Image,
  IconButton,
} from '@wix/design-system';
import { Add } from '@wix/wix-ui-icons-common';
import { Section } from '../Section/Section';
import { TRANSLATIONS } from '../../../constants/translations';
import {
  AUTOMATION_IDS,
  getUninstalledAppCardDataHook,
} from '../../../constants/automationIds';

import type { RegularAppData } from '../../../services/appMarket/appMarket.types';
import styles from './MoreApps.scss';
import { t } from '../../../../../essentials/i18n';

export interface MoreAppsProps {
  apps: RegularAppData[];
  onAddApp: (app: RegularAppData) => void;
  onOpenAppMarket: () => void;
}

export const MoreApps: React.FC<MoreAppsProps> = ({
  apps,
  onAddApp,
  onOpenAppMarket,
}) => {
  return (
    <Section
      heading={t(TRANSLATIONS.MY_BUSINESS.MORE_APPS.SECTION_TITLE)}
      linkText={t(TRANSLATIONS.MY_BUSINESS.MORE_APPS.APP_MARKET_LINK)}
      dataHook={AUTOMATION_IDS.MY_BUSINESS.UNINSTALLED_APPS_CONTAINER}
      onLinkClick={onOpenAppMarket}
    >
      {apps.map((appData, index) => (
        <TableListItem
          key={appData.id}
          dataHook={getUninstalledAppCardDataHook(appData.id)}
          className={styles.itemContainer}
          verticalPadding="medium"
          showDivider={index !== apps.length - 1}
          onClick={() => onAddApp(appData)}
          options={[
            {
              value: (
                <Image
                  width="48px"
                  height="48px"
                  src={appData.icon}
                  transparent
                />
              ),
              width: '48px',
              align: 'left',
            },
            {
              value: (
                <Box direction="vertical">
                  <Text size="small" weight="normal">
                    {appData.name}
                  </Text>
                  <Text
                    size="small"
                    weight="thin"
                    secondary
                    maxLines={2}
                    ellipsis
                  >
                    {appData.description}
                  </Text>
                </Box>
              ),
              width: 'auto',
            },
            {
              value: (
                <IconButton priority="secondary" size="tiny">
                  <Add />
                </IconButton>
              ),
              align: 'right',
            },
          ]}
        />
      ))}
    </Section>
  );
};
