import React from 'react';
import { Text, TextButton, Box } from '@wix/design-system';
import styles from './Section.scss';

export const Section: React.FC<{
  heading?: string | React.ReactNode;
  children: React.ReactNode;
  linkText?: string;
  onLinkClick?: () => void;
  dataHook?: string;
}> = ({ heading, children, linkText, onLinkClick, dataHook }) => (
  <Box padding="SP3 SP4" direction="vertical" dataHook={dataHook}>
    <Box align="space-between">
      {typeof heading === 'string' ? (
        <Text size="small" weight="normal">
          {heading}
        </Text>
      ) : (
        heading
      )}

      {linkText && (
        <TextButton size="small" weight="thin" onClick={onLinkClick}>
          {linkText}
        </TextButton>
      )}
    </Box>

    <Box
      direction="vertical"
      marginTop="SP2"
      border="1px solid"
      borderColor="D60"
      borderRadius={4}
      className={styles.content}
    >
      {children}
    </Box>
  </Box>
);
