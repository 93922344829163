// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vTbXu8{min-height:24px}.P3_omC{display:grid;min-width:5em}.GXvIIZ [data-hook=setup-step-title-text]{text-decoration:line-through}.aN3rO7{pointer-events:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `vTbXu8`,
	"title": `P3_omC`,
	"titleCrossed": `GXvIIZ`,
	"checkboxContainer": `aN3rO7`
};
export default ___CSS_LOADER_EXPORT___;
