import {
  WIX_VIDEO,
  WIX_CHAT,
  WIX_SITE_SEARCH,
  WIX_FILE_SHARE,
  WIX_FAQ,
} from '@wix/app-definition-ids';

export const META_SITE_APPLICATION_ID = '-666';

export const HELP_ARTICLE_IDS = {
  APP_LIST: 'adc4b199-9239-475a-ba0d-a5051d4242db',
  MY_BUSINESS: 'adc4b199-9239-475a-ba0d-a5051d4242db',
};
export const APP_MANAGER_ORIGIN = 'app-manager';

export const APP_ICON_SIZE = 42;
export const SETUP_SUCCESS_ANIMATION_DURATION = 1000 * 5;
export const DEFAULT_TRANSITION_TIMEOUT = 500;
export const MIN_SUCCESS_SETUP_HEIGHT = 90;

export const LITE_MODE_FILTERED_APPS = [
  WIX_VIDEO,
  WIX_CHAT,
  WIX_SITE_SEARCH,
  WIX_FAQ,
  WIX_FILE_SHARE,
];

export const INTERACTIONS = {
  APP_MANAGER: {
    LOAD: 'app_manager_load',
    FETCH_WIX_APPS: 'app_manager_fetch_wix_apps',
    FETCH_INSTALLED_APPS: 'app_manager_fetch_installed_apps',
    FETCH_DEALER_INFO: 'app_manager_fetch_dealer_info',
    FETCH_DEALER_SEGMENT_APPS: 'app_manager_fetch_dealer_segment_apps',
    FETCH_DEALER_SEGMENT_CONTENT: 'app_manager_fetch_dealer_segment_content',
    INIT_SEGMENTS: 'app_manager_init_segments',
    FETCH_SETUP_STEPS: 'app_manager_fetch_setup_steps',
    FETCH_RECOMMENDED_TOOLS: 'app_manager_fetch_recommended_tools',
  },
};

export type AppManagerInteractionName =
  (typeof INTERACTIONS.APP_MANAGER)[keyof typeof INTERACTIONS.APP_MANAGER];
