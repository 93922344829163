import React, { FC, useEffect, useState } from 'react';
import { t } from '../../../essentials/i18n';
import {
  Modal,
  CustomModalLayout,
  Text,
  Heading,
  SectionHelper,
  Layout,
  Cell,
  Avatar,
} from '@wix/design-system';

import { getBodyContentByOrigin } from './utils';
import { DeleteAppOriginSource, dataHooks, KEYS } from './constants';
import { PanelResolveType } from '@wix/editor-platform-sdk-types';

export type DeleteAppOrigin = {
  source: DeleteAppOriginSource;
  name?: string;
};

export interface DeleteAppModalProps {
  keepModalOpen?: boolean;
  origin: DeleteAppOrigin;
  appName: string;
  appIconUrl?: string;
  appNote?: React.ReactNode;
  openHelpCenter: () => void;
  onClose: (closeType: PanelResolveType) => void;
  displayFootnote?: boolean;
  zIndex: number;
}

export const DeleteAppModalContainer: FC<DeleteAppModalProps> = ({
  keepModalOpen = true,
  origin,
  appName,
  appIconUrl,
  appNote,
  openHelpCenter,
  onClose,
  displayFootnote,
  zIndex,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (!keepModalOpen) {
      setIsModalOpen(false);
      onClose(PanelResolveType.CLOSE_ACTION);
    }
  }, [keepModalOpen, onClose]);

  return (
    <Modal
      dataHook={dataHooks.modalLayout}
      isOpen={isModalOpen}
      zIndex={zIndex}
    >
      <CustomModalLayout
        dataHook={dataHooks.customModalLayout}
        width="600px"
        maxHeight="calc(100vh - (48px*2))"
        theme="destructive"
        onHelpButtonClick={openHelpCenter}
        onCloseButtonClick={() => {
          setIsModalOpen(false);
          onClose(PanelResolveType.CLOSE_ACTION);
        }}
        title={
          <Layout alignItems="center" gap="12px">
            <Cell span={1}>
              <Avatar
                dataHook={dataHooks.avatar}
                shape="square"
                imgProps={{
                  src: appIconUrl,
                }}
                size="size30"
              />
            </Cell>
            <Cell span={11}>
              <Heading
                dataHook={dataHooks.headingTitle}
                size="large"
                maxLines={2}
                ellipsis
              >
                {t(KEYS.headingTitle, {
                  app_name: appName,
                })}
              </Heading>
            </Cell>
          </Layout>
        }
        content={
          <Layout gap="18px">
            <Cell>
              <Text
                dataHook={dataHooks.bodyContent}
                size="medium"
                weight="thin"
              >
                {getBodyContentByOrigin(origin, appName)}
              </Text>
            </Cell>
            <Cell>
              <SectionHelper
                dataHook={dataHooks.note}
                appearance="preview"
                size="small"
              >
                {appNote ? (
                  appNote
                ) : (
                  <>
                    <Text size="small" weight="bold">
                      {t(KEYS.noteDefaultTextHeading)}{' '}
                    </Text>
                    <Text size="small" secondary>
                      {t(KEYS.noteDefaultTextBody, {
                        app_name: appName,
                      })}
                    </Text>
                  </>
                )}
              </SectionHelper>
            </Cell>
          </Layout>
        }
        primaryButtonText={t(KEYS.deleteAppButton)}
        primaryButtonOnClick={() => onClose(PanelResolveType.MAIN_ACTION)}
        secondaryButtonText={t(KEYS.cancelButton)}
        secondaryButtonOnClick={() => {
          setIsModalOpen(false);
          onClose(PanelResolveType.SECONDARY_ACTION);
        }}
        footnote={
          displayFootnote ? (
            <Text dataHook={dataHooks.footnote} size="small">
              {t(KEYS.footnoteText)}{' '}
              <a
                href={t(KEYS.footnoteLinkUrl)}
                target="_blank"
                rel="noreferrer"
              >
                {t(KEYS.footnoteLinkText)}
              </a>
            </Text>
          ) : null
        }
      />
    </Modal>
  );
};
