export enum DeleteAppOriginSource {
  UninstallAction = 0,
  PagesTabDeleteAction = 1,
  EssentialPageDeleted = 2,
  EssentialWidgetDeleted = 3,
}

export const dataHooks = {
  modalLayout: 'delete-app-modal-wrapper-layout',
  customModalLayout: 'delete-app-custom-modal-layout',
  avatar: 'delete-app-icon',
  headingTitle: 'delete-app-modal-title',
  bodyContent: 'delete-app-modal-body-content',
  note: 'delete-app-modal-note',
  footnote: 'delete-app-modal-footnote',
};

export const KEYS = {
  headingTitle: 'platformized_app_delete_title',
  bodyContent: {
    [DeleteAppOriginSource.UninstallAction]: 'platformized_app_delete_body',
    [DeleteAppOriginSource.PagesTabDeleteAction]:
      'platformized_app_delete_page_category_body',
    [DeleteAppOriginSource.EssentialPageDeleted]:
      'platformized_app_delete_page_body',
    [DeleteAppOriginSource.EssentialWidgetDeleted]:
      'platformized_app_delete_essential_widget_body',
  },
  noteDefaultTextHeading: 'platformized_app_delete_default_note',
  noteDefaultTextBody: 'platformized_app_delete_default_note_body',
  deleteAppButton: 'platformized_app_delete_main_button',
  cancelButton: 'platformized_app_delete_secondary_button',
  footnoteText: 'platformized_app_delete_payments_footer_text',
  footnoteLinkText: 'platformized_app_delete_payments_footer_cta',
  footnoteLinkUrl: 'platformized_app_delete_payments_footer_url',
};
