import {
  DealerOffersServingService,
  type Offer,
} from '@wix/ambassador-dealer-offers-serving-service/http';
import { parse } from '@wix/deeplink-core';
import { check, show } from '@wix/deeplink-editor';

import { DealerActionType, type DealerRawAction } from './dealer.types';
import { AppManagerApiDependencies } from '../../../../types/appManager';

const DEALER_URL = '/_api/dealer-offers-serving-service';

export const dealerService =
  DealerOffersServingService(DEALER_URL).DealerOffersServing();

export const ESTATES = {
  SEGMENT_CONTENT: 'b2ecb04d-90e6-4448-910e-113cf6085ebe', // https://bo.wix.com/dealer/placements/b2ecb04d-90e6-4448-910e-113cf6085ebe?group-by=campaign
  MANAGE_APPS: '80db1bf3-d307-4cfa-ae5d-5d9cfe817329', // https://bo.wix.com/dealer/placements/80db1bf3-d307-4cfa-ae5d-5d9cfe817329
  SEGMENT_APPS: '9b596609-1613-43ce-91ea-b738cf320473', //  https://bo.wix.com/dealer/placements/9b596609-1613-43ce-91ea-b738cf320473?group-by=campaign
  EDITOR_SETUP: '0c57e11b-e9a5-4a6e-899f-38ea2dc1ae03', // https://bo.wix.com/dealer/placements/0c57e11b-e9a5-4a6e-899f-38ea2dc1ae03
  RECOMMENDED_TOOLS: 'a53e458a-531b-4899-96dd-19253ea6b3aa', // https://bo.wix.com/dealer/placements/a53e458a-531b-4899-96dd-19253ea6b3aa
};

export const parseResponseOffers = <T extends unknown>(
  offers: Offer[] = [],
): T[] =>
  offers
    .map((offer) => offer?.asset?.payloadJson)
    .filter(Boolean)
    .map((jsonPayload) => JSON.parse(jsonPayload as string));

export const generateActionFromDealerData = (
  action: DealerRawAction,
  deps: AppManagerApiDependencies,
): (() => void) | void => {
  if (!action?.value) {
    return;
  }

  switch (action.type) {
    case DealerActionType.BMLink:
      return () => deps.openSettings({ path: action.value });
    case DealerActionType.EditorDeepLink:
      let feature: any;
      try {
        feature = parse(action.value);
      } catch (e) {
        //
      }

      return async () => {
        const isFeatureAvailable = feature && (await check(feature));

        if (isFeatureAvailable) {
          return show(feature);
        }
      };

    default:
      break;
  }
};
