import React from 'react';
import { useBILogger } from '@wix/fe-essentials/react';
import {
  TableListItem,
  Box,
  Text,
  Image,
  IconButton,
} from '@wix/design-system';
import { ChevronRight } from '@wix/wix-ui-icons-common';
import { Section } from '../Section/Section';
import { getRecommendedToolDataHook } from '../../../constants/automationIds';
import { BI_EVENTS_IDS } from '../../../constants/biEvents';
import { RecommendedTool } from '../../../services/recommendedTools/RecommendedTools';
import { TRANSLATIONS } from '../../../constants/translations';
import styles from './RecommendedTools.scss';
import { t } from '../../../../../essentials/i18n';
import { myBusinessRecommendedToolsClick } from '@wix/bi-logger-editor-x/v2';

interface RecommendedToolsProps {
  tools: RecommendedTool[];
}

export const RecommendedTools: React.FC<RecommendedToolsProps> = ({
  tools,
}) => {
  const logger = useBILogger();

  const onToolClick = (tool: RecommendedTool) => {
    tool.executeAction();
    logger.log({
      evid: BI_EVENTS_IDS.RECOMMENDED_TOOL_CLICKED,
      tool: t(tool.name, { lng: 'en' }),
    });
    logger.report(
      myBusinessRecommendedToolsClick({
        tool: t(tool.name, { lng: 'en' }),
      }),
    );
  };

  return (
    <Section heading={t(TRANSLATIONS.MY_BUSINESS.HEADERS.RECOMMENDED_TOOLS)}>
      {tools.map((tool, index) => (
        <TableListItem
          key={tool.metadata.offerId}
          dataHook={getRecommendedToolDataHook(tool.metadata.offerId)}
          className={styles.itemContainer}
          verticalPadding="medium"
          showDivider={index !== tools.length - 1}
          onClick={() => onToolClick(tool)}
          options={[
            {
              value: (
                <Image
                  width="48px"
                  height="48px"
                  src={tool.imageSrc}
                  alt={t(tool.name)}
                  transparent
                />
              ),
              width: '48px',
              align: 'left',
            },
            {
              value: (
                <Box direction="vertical">
                  <Text size="small" weight="normal">
                    {t(tool.name)}
                  </Text>
                  <Text
                    size="small"
                    weight="thin"
                    secondary
                    maxLines={2}
                    ellipsis
                  >
                    {t(tool.description)}
                  </Text>
                </Box>
              ),
              width: 'auto',
            },
            {
              value: (
                <IconButton priority="secondary" size="tiny">
                  <ChevronRight />
                </IconButton>
              ),
              align: 'right',
            },
          ]}
        />
      ))}
    </Section>
  );
};
