// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BJaQLF{display:flex;flex:auto;height:100%}.y9Bd9s{height:100%;display:flex;flex-direction:column}.I1L9oI{min-width:210px;width:210px}.EJIDb6{height:5px !important;width:5px !important;min-width:5px !important;padding:0 !important;margin-left:2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BJaQLF`,
	"content": `y9Bd9s`,
	"sidebar": `I1L9oI`,
	"dot": `EJIDb6`
};
export default ___CSS_LOADER_EXPORT___;
