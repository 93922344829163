import React, { FC, useMemo } from 'react';
import { AppManagerProps } from '../../types/appManager';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { EditorPlatformBIProvider } from '../providers/EditorPlatformBIProvider';
import { EditorPlatformFedopsProvider } from '../providers/EditorPlatformFedopsProvider';
import { EditorPlatformI18nProvider } from '../providers/EditorPlatformI18nProvider';
import { AppManagerContainer } from './AppManagerContainer/AppManagerContainer';
import { getAppManagerPrivateAPI } from './appManagerAPI';
import { AppManagerDataProvider } from './providers/AppManagerDataProvider';
import { WixDesignSystemProvider } from '@wix/design-system';

export const AppManager: FC<AppManagerProps> = ({
  appsList,
  pendingAppDefIds,
  userPermissions,
  allAvailableToUpdateApps,
  apiDependencies,
  onPanelTitleReady,
  selectedAppId,
  selectedAppFromUrl,
}) => {
  const api = useMemo(
    () =>
      getAppManagerPrivateAPI(
        appsList,
        pendingAppDefIds,
        userPermissions,
        allAvailableToUpdateApps,
        apiDependencies,
      ),
    [
      appsList,
      pendingAppDefIds,
      userPermissions,
      allAvailableToUpdateApps,
      apiDependencies,
    ],
  );

  const EDITOR_BI_SRC = 38;
  const EDITOR_BI_ENDPOINT = 'editor';

  const biBrops = {
    src: EDITOR_BI_SRC,
    endpoint: EDITOR_BI_ENDPOINT,
  };

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <EditorPlatformI18nProvider>
        <EditorPlatformBIProvider {...biBrops}>
          <EditorPlatformFedopsProvider>
            <ExperimentsProvider>
              <AppManagerDataProvider api={api}>
                <AppManagerContainer
                  selectedAppId={selectedAppId}
                  selectedAppFromUrl={selectedAppFromUrl}
                  onPanelTitleReady={onPanelTitleReady}
                />
              </AppManagerDataProvider>
            </ExperimentsProvider>
          </EditorPlatformFedopsProvider>
        </EditorPlatformBIProvider>
      </EditorPlatformI18nProvider>
    </WixDesignSystemProvider>
  );
};
