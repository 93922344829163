import React, { useCallback, useContext, useRef } from 'react';
import { PlatformFrameContext } from './PlatformFrameContextProvider';

export interface IPlatformFrameProps {
  appDefinitionId: string;
  children: (
    setRef: (dom: HTMLIFrameElement | null) => void,
  ) => React.ReactNode;
}

export const PlatformFrame: React.FC<IPlatformFrameProps> = ({
  children,
  appDefinitionId,
}) => {
  const frameRef = useRef<HTMLIFrameElement | null>();
  const platformFrameContext = useContext(PlatformFrameContext);

  const setRef = useCallback(
    (ref: HTMLIFrameElement | null) => {
      frameRef.current = ref;

      const isValidProps = () => {
        return appDefinitionId && frameRef.current;
      };

      if (isValidProps()) {
        if (typeof platformFrameContext?.getWorkerManager !== 'function') {
          console.warn('PlatformFrame - getWorkerManager is not a function');
        } else {
          platformFrameContext?.getWorkerManager().then((workerManager) => {
            /**
             * we check isValidProps() twice since `getWorkerManager` is async operation,
             * and we want to be sure after it is done everything is still valid
             * and component is still mounted
             */
            if (workerManager && isValidProps()) {
              void workerManager.connectFrame(
                appDefinitionId,
                frameRef.current!,
              );
            }
          });
        }
      }
    },
    [appDefinitionId, platformFrameContext],
  );

  return children(setRef);
};
