import { t } from '../../../essentials/i18n';
import { DeleteAppOrigin } from './DeleteAppModalContainer';
import { DeleteAppOriginSource, KEYS } from './constants';

export const getBodyContentByOrigin = (
  origin: DeleteAppOrigin,
  appName: string,
) => {
  switch (origin.source) {
    case DeleteAppOriginSource.UninstallAction:
      return t(KEYS.bodyContent[DeleteAppOriginSource.UninstallAction], {
        app_name: appName,
      });
    case DeleteAppOriginSource.PagesTabDeleteAction:
      return t(KEYS.bodyContent[DeleteAppOriginSource.PagesTabDeleteAction], {
        page_category: origin.name,
        app_name: appName,
      });
    case DeleteAppOriginSource.EssentialPageDeleted:
      return t(KEYS.bodyContent[DeleteAppOriginSource.EssentialPageDeleted], {
        page_name: origin.name,
        app_name: appName,
      });
    case DeleteAppOriginSource.EssentialWidgetDeleted:
      return t(KEYS.bodyContent[DeleteAppOriginSource.EssentialWidgetDeleted], {
        component_name: origin.name,
        app_name: appName,
      });
  }
};
