import React, { useCallback, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Box, Loader } from '@wix/design-system';
import { APP_MANAGER_ORIGIN, INTERACTIONS } from '../constants/constants';
import type { RegularAppData } from '../services/appMarket/appMarket.types';
import { AUTOMATION_IDS } from '../constants/automationIds';
import { BI_EVENTS_IDS } from '../constants/biEvents';
import { prepareSegmentsAppsData } from '../services/dealer/dealer.segmentApps';
import { AppManagerDataContext } from '../providers/AppManagerDataProvider';
import { ErrorBoundary } from '../../shared/util';
import { getEditorSessionId } from '../../../utils';
import { EditorPlatformFedopsContext } from '../../providers/EditorPlatformFedopsProvider';
import { ErrorState } from './components';
import { useBILogger } from '@wix/fe-essentials/react';
import { MyBusiness } from './MyBusiness/MyBusiness';
import styles from './AppManagerContainer.scss';
import { AppManagerOwnProps } from '../../../types/appManager';

export const AppManagerContainer: React.FC<AppManagerOwnProps> = ({
  selectedAppId,
  selectedAppFromUrl,
}) => {
  const { api, data } = useContext(AppManagerDataContext);
  const { fedopsLogger } = useContext(EditorPlatformFedopsContext);
  const logger = useBILogger();

  const {
    segmentApps: [
      segmentApps,
      { isLoading: isLoadingSegmentApps, error: errorFromLoadSegmentApps },
    ],
    dealerData: [
      dealerData,
      { isLoading: isLoadingDealer, error: errorFromLoadDealerData },
    ],
    allApps: [
      allApps,
      { isLoading: isLoadingWixApps, error: errorFromGetWixApps },
    ],
    segmentContent: [
      segmentContent,
      { isLoading: isLoadingSegmentContent, error: errorFromSegmentContent },
    ],
  } = data;

  const handleError = useCallback(
    (error: Error) => {
      logger.log({
        evid: BI_EVENTS_IDS.PANEL_ERROR,
        esi: getEditorSessionId(),
        message: error?.message || 'not provided',
      });
    },
    [logger],
  );

  const hasSegmentApps = segmentApps && segmentApps.length > 0;

  const getAvailableApps = (): RegularAppData[] | undefined => {
    const availableApps = allApps?.filter((app: any) =>
      app && app.isInstalled ? api.checkIsDescriptorExists(app.id || '') : true,
    ) as RegularAppData[];

    if (hasSegmentApps) {
      return prepareSegmentsAppsData(availableApps, segmentApps);
    }

    return availableApps;
  };

  const availableApps = getAvailableApps();

  const [installedOrPendingApps, moreApps] = _.partition(
    availableApps,
    (app) => app.isInstalled || app.isPending,
  );

  const error =
    errorFromLoadDealerData ||
    errorFromGetWixApps ||
    errorFromLoadSegmentApps ||
    errorFromSegmentContent;

  if (error) {
    throw error;
  }

  // `hasInstalledApps` is only `true` if the site has one of managed apps
  // installed, so if it is `true` and `installedOrPendingApps` is empty, it
  // means that the apps' manifests haven't been loaded yet, so we keep the
  // Preloader spinning until at least one manifest is loaded
  const isLoadingAppManifests =
    api.hasInstalledApps && installedOrPendingApps.length === 0;

  const isLoadingData =
    isLoadingWixApps ||
    isLoadingDealer ||
    isLoadingSegmentApps ||
    isLoadingSegmentContent ||
    isLoadingAppManifests;

  useEffect(
    () => {
      if (isLoadingData) {
        fedopsLogger.interactionStarted(INTERACTIONS.APP_MANAGER.LOAD);
        return;
      }

      fedopsLogger.interactionEnded(INTERACTIONS.APP_MANAGER.LOAD);
      const initialApp = availableApps?.find(
        (app) => app.id === selectedAppId || app.slug === selectedAppFromUrl,
      );

      const biParams = {
        evid: BI_EVENTS_IDS.PANEL_LOADED,
        isSegment: true,
        tab_name: initialApp?.id,
        segment_info: installedOrPendingApps.map((app) => app.id),
      };

      logger.log(biParams);
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingData],
  );

  if (isLoadingData) {
    return (
      <Box width="100%" height="100%" align="center" verticalAlign="middle">
        <Loader />
      </Box>
    );
  }

  return (
    <div className={styles.content} data-aid={AUTOMATION_IDS.PANEL_CONTENT}>
      <ErrorBoundary
        ravenConfig={{
          tags: {
            [APP_MANAGER_ORIGIN]: true,
          },
        }}
        renderError={(errorData) => (
          <ErrorState error={errorData} segmentContent={segmentContent} />
        )}
        onError={handleError}
      >
        <MyBusiness
          moreApps={moreApps}
          apps={installedOrPendingApps}
          initialSelectedAppId={selectedAppId}
          segmentContent={segmentContent}
          dealerData={dealerData}
        />
      </ErrorBoundary>
    </div>
  );
};
