import React, { type FC } from 'react';
import { Box, Text, TextButton } from '@wix/design-system';
import cx from 'classnames';
import { TRANSLATIONS } from '../../../constants/translations';
import styles from './SetupSuccessState.scss';
import { t } from '../../../../../essentials/i18n';

export interface SetupSuccessStateProps {
  appName: string;
  dataHook: string;
  onDismiss: () => void;
}

export const SetupSuccessState: FC<SetupSuccessStateProps> = ({
  appName,
  dataHook,
  onDismiss,
}) => {
  return (
    <Box
      className={cx(styles.successState, styles.container)}
      data-hook={dataHook}
      verticalAlign="middle"
      align="center"
      direction="vertical"
    >
      <Text size="medium" weight="normal">
        {t(TRANSLATIONS.MY_BUSINESS.SETUP_STEP.COMPLETE_HEADER)}
      </Text>

      <Text size="small" weight="thin" secondary>
        {t(TRANSLATIONS.MY_BUSINESS.SETUP_STEP.COMPLETE_TEXT, {
          app_name: appName,
        })}
      </Text>

      <Box marginTop="SP2">
        <TextButton size="small" onClick={onDismiss}>
          {t(TRANSLATIONS.MY_BUSINESS.SETUP_STEP.COMPLETE_CTA)}
        </TextButton>
      </Box>
    </Box>
  );
};
