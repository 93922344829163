import React from 'react';

export const PluginFallbackLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="#ECEFF3" />
    <rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="#ECEFF3" />
    <path
      d="M13.6099 23.6002L12.8999 22.8902C12.342 22.3329 11.8994 21.6712 11.5975 20.9428C11.2956 20.2144 11.1401 19.4337 11.1401 18.6452C11.1401 17.8567 11.2956 17.0759 11.5975 16.3475C11.8994 15.6191 12.342 14.9574 12.8999 14.4002V14.4002L22.0999 23.6002C21.5426 24.158 20.8809 24.6006 20.1525 24.9025C19.4241 25.2045 18.6433 25.3599 17.8549 25.3599C17.0664 25.3599 16.2856 25.2045 15.5572 24.9025C14.8288 24.6006 14.1671 24.158 13.6099 23.6002ZM24.2199 22.8902C24.3136 22.7972 24.388 22.6866 24.4387 22.5648C24.4895 22.4429 24.5157 22.3122 24.5157 22.1802C24.5157 22.0482 24.4895 21.9175 24.4387 21.7956C24.388 21.6737 24.3136 21.5631 24.2199 21.4702L14.9999 12.2802C14.9069 12.1864 14.7963 12.1121 14.6744 12.0613C14.5526 12.0105 14.4219 11.9844 14.2899 11.9844C14.1578 11.9844 14.0271 12.0105 13.9053 12.0613C13.7834 12.1121 13.6728 12.1864 13.5799 12.2802V12.2802C13.4861 12.3731 13.4117 12.4837 13.361 12.6056C13.3102 12.7275 13.2841 12.8582 13.2841 12.9902C13.2841 13.1222 13.3102 13.2529 13.361 13.3747C13.4117 13.4966 13.4861 13.6072 13.5799 13.7002L22.7699 22.8902C22.8633 22.9885 22.9757 23.0668 23.1004 23.1203C23.225 23.1738 23.3592 23.2014 23.4949 23.2014C23.6305 23.2014 23.7647 23.1738 23.8894 23.1203C24.014 23.0668 24.1264 22.9885 24.2199 22.8902ZM21.2799 11.6902C21.4661 11.5028 21.5707 11.2494 21.5707 10.9852C21.5707 10.721 21.4661 10.4675 21.2799 10.2802C21.1869 10.1864 21.0763 10.1121 20.9544 10.0613C20.8326 10.0105 20.7019 9.98438 20.5699 9.98438C20.4378 9.98438 20.3071 10.0105 20.1853 10.0613C20.0634 10.1121 19.9528 10.1864 19.8599 10.2802L17.0699 13.0702L18.4799 14.4802L21.2799 11.6902ZM26.2199 16.6902C26.3136 16.5972 26.388 16.4866 26.4387 16.3647C26.4895 16.2429 26.5157 16.1122 26.5157 15.9802C26.5157 15.8482 26.4895 15.7175 26.4387 15.5956C26.388 15.4737 26.3136 15.3631 26.2199 15.2702C26.0325 15.0839 25.779 14.9794 25.5149 14.9794C25.2507 14.9794 24.9972 15.0839 24.8099 15.2702L21.9999 18.0002L23.4099 19.4102L26.2199 16.6902Z"
      fill="#3B4057"
    />
  </svg>
);
