import React from 'react';
import { Text, Box, CircularProgressBar } from '@wix/design-system';
import { AUTOMATION_IDS } from '../../../constants/automationIds';
import { TRANSLATIONS } from '../../../constants/translations';
import { t } from '../../../../../essentials/i18n';

export interface BusinessAppSetupHeaderProps {
  appDefId: string;
  appName: string;
  stepsCompleted: number;
  stepsTotal: number;
  showProgress: boolean;
}

export const BusinessAppSetupHeader: React.FC<BusinessAppSetupHeaderProps> = ({
  appDefId,
  appName,
  stepsCompleted,
  stepsTotal,
  showProgress,
}) => {
  const key = appDefId as keyof typeof TRANSLATIONS.MY_BUSINESS.SETUP_HEADER;
  const translationKeys = TRANSLATIONS.MY_BUSINESS.SETUP_HEADER[key] || {
    TITLE: `Start Using ${appName}`,
    SUBTITLE: 'Complete the steps below',
  };
  const progress = (stepsCompleted / stepsTotal) * 100;

  return (
    <Box
      dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.HEADER}
      width="100%"
      minHeight="60px"
      gap="SP3"
      align="space-between"
      verticalAlign="middle"
    >
      <Box direction="vertical">
        <Text size="small" weight="normal">
          {t(translationKeys.TITLE)}
        </Text>
        <Text size="small" weight="thin" secondary>
          {t(translationKeys.SUBTITLE)}
        </Text>
      </Box>

      {showProgress && (
        <Box>
          <CircularProgressBar
            showProgressIndication
            size="small"
            skin="success"
            value={progress}
            label={`${stepsCompleted} / ${stepsTotal}`}
          />
        </Box>
      )}
    </Box>
  );
};
