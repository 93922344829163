import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import { AppManagerInteractionName } from '../constants/constants';
import { EditorPlatformFedopsContext } from '../../providers/EditorPlatformFedopsProvider';
import { useEffect, useState, useCallback, useContext } from 'react';

interface UseRequestDataOptions<T, K extends AppManagerInteractionName> {
  initialData?: T;
  fedopsInteractionName: K;
  enabled?: boolean;
}

export type UseRequestResponse<T, E = Error> = [
  T | null,
  {
    isLoading: boolean;
    error: E | null;
    retry: () => void;
  },
];

export const useRequest = <
  T,
  K extends AppManagerInteractionName,
  E extends Error = AmbassadorHTTPError,
>(
  request: () => Promise<T>,
  options?: UseRequestDataOptions<T, K>,
): UseRequestResponse<T> => {
  const shouldBeCalled = options?.enabled ?? true;

  const [attempts, setAttempts] = useState(1);
  const retry = useCallback(() => {
    setAttempts((current) => current + 1);
  }, [setAttempts]);
  const [isLoading, setLoading] = useState(!!shouldBeCalled);
  const [error, setError] = useState<E | null>(null);
  const [data, setData] = useState<T | null>(options?.initialData || null);
  const { fedopsLogger } = useContext(EditorPlatformFedopsContext);

  useEffect(
    () => {
      if (!shouldBeCalled) {
        return;
      }

      let isAborted = false;

      if (options?.fedopsInteractionName) {
        fedopsLogger.interactionStarted(options.fedopsInteractionName);
      }

      request()
        .then((response) => {
          if (options?.fedopsInteractionName) {
            fedopsLogger.interactionEnded(options.fedopsInteractionName);
          }
          if (isAborted) {
            return;
          }
          setData(response);
          setLoading(false);
          setError(null);
        })
        .catch((errorResponse: E) => {
          if (isAborted) {
            return;
          }
          setData(null);
          setError(errorResponse);
          setLoading(false);
        });

      return () => {
        isAborted = true;
      };
    }, // TODO: Fix this the next time the file is edited. request is usually arrow function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attempts, shouldBeCalled, options?.fedopsInteractionName ?? null],
  );

  return [data, { isLoading, error, retry }] as any;
};
