import { isDescriptorExists } from './services/platform/platform';
import * as appMarketUtils from '../../apis/utils/appMarketUtils';
import { AppManagerApiDependencies } from '../../types/appManager';

export const isSupportedApp = (
  supportedApps: string[],
  appDefinitionId: string,
  deps: {
    getAppDataByAppDefId: AppManagerApiDependencies['getAppDataByAppDefId'];
    getAppDescriptor: AppManagerApiDependencies['getAppDescriptor'];
  },
) =>
  supportedApps.includes(appDefinitionId) ||
  (appMarketUtils.isBuilderType(
    deps.getAppDataByAppDefId(appDefinitionId) as any,
  ) &&
    isDescriptorExists(appDefinitionId, deps));

export const memoizePromise = <
  T extends (...args: any[]) => Promise<any>,
  P extends Parameters<T>,
>(
  fn: T,
) => {
  let promise: any;

  const memoizedFn = (...args: P) => {
    if (!promise) {
      promise = fn(...args).catch((e) => {
        promise = undefined;
        throw e;
      });
    }

    return promise;
  };

  return memoizedFn as (...args: P) => ReturnType<T>;
};
