import { DeleteSmall } from '@wix/wix-ui-icons-common';
import { AddBold } from '@wix/wix-ui-icons-common/classic-editor';
import React, { VFC, useState } from 'react';
import { Box, Button, Loader, MediaOverlay } from '@wix/design-system';
import { useTranslation } from '../../useTranslation';
import { Card } from './addonsCard';
import { Addon } from './types';
import { useAddons } from './useAddons';

interface Props {
  addon: Addon;
}

const CTAButton: VFC<{
  children: string;
  shouldRenderLoader: boolean;
  disabled: boolean;
  icon: JSX.Element;
  onClick: () => void;
}> = ({ disabled, icon, shouldRenderLoader, onClick, children }) => {
  if (shouldRenderLoader) {
    return (
      <Button skin="light" size="small">
        <Loader size="tiny" />
      </Button>
    );
  }
  return (
    <Button
      prefixIcon={icon}
      skin="light"
      size="small"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export const GridItem: VFC<Props> = ({ addon }) => {
  const [shouldRenderLoader, setShouldRenderLoader] = useState(false);
  const [t] = useTranslation();
  const { isInstallationInProgress, installAddon, uninstallAddon } =
    useAddons();

  const ctaButton = addon.isInstalled ? (
    <CTAButton
      icon={<DeleteSmall />}
      shouldRenderLoader={shouldRenderLoader}
      disabled={isInstallationInProgress}
      onClick={() => {
        uninstallAddon(addon.appDefinitionId, {
          origin: 'addon_market_card',
        });
      }}
    >
      {t('PLATFORM_addons_panel_addon_card_remove_button')}
    </CTAButton>
  ) : (
    <CTAButton
      icon={<AddBold />}
      shouldRenderLoader={shouldRenderLoader}
      disabled={isInstallationInProgress}
      onClick={() => {
        setShouldRenderLoader(true);
        installAddon(addon.appDefinitionId, {
          origin: 'addon_market_card',
        }).then(() => {
          setShouldRenderLoader(false);
        });
      }}
    >
      {t('PLATFORM_addons_panel_addon_card_add_button')}
    </CTAButton>
  );

  return (
    <MediaOverlay
      borderRadius={6}
      media={
        <Card
          addon={addon}
          isInstalled={addon.isInstalled}
          price={t('PLATFORM_addons_panel_addon_card_price_free_label')}
        />
      }
      skin={shouldRenderLoader ? 'dark' : 'none'}
      hoverSkin="dark"
    >
      <MediaOverlay.Content visible={shouldRenderLoader ? 'always' : 'hover'}>
        <Box direction="vertical" align="center">
          {ctaButton}
        </Box>
      </MediaOverlay.Content>
    </MediaOverlay>
  );
};
