import { ErrorReporter } from '@wix/editor-error-reporter';
import { loadSegmentContent } from './services/dealer/dealer.segmentContent';
import { memoizePromise } from './utils';
import { isNewWorkspaceEnabled } from '../shared/util/workspace';
import { APP_MANAGER_ORIGIN, INTERACTIONS } from './constants/constants';

interface LeftMenuPatch {
  label?: string;
  symbolName?: string;
  className?: string;
}

const init = async (
  metaSiteInstance: string,
  patchLeftMenuCb: (patches: LeftMenuPatch) => void,
) => {
  try {
    const offer = await loadSegmentContent(metaSiteInstance);

    if (offer) {
      const patches: LeftMenuPatch = {};

      const symbolName = isNewWorkspaceEnabled()
        ? offer.leftBarButtonIconName
        : offer.leftBarButtonIconNameOldWorkspace;

      if (offer.leftBarButtonName) {
        patches.label = offer.leftBarButtonName;
      }

      if (symbolName) {
        patches.symbolName = symbolName;
        patches.className = `am-${symbolName}`;
      }

      if (Object.keys(patches).length > 0) {
        patchLeftMenuCb(patches);
      }
    }
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        [APP_MANAGER_ORIGIN]: true,
      },
    });
  }
};

export const initSegments = memoizePromise(
  async (
    metaSiteInstance,
    patchLeftMenuCb: (patches: LeftMenuPatch) => void,
    interactionStartedCb,
    interactionEndedCb,
  ) => {
    interactionStartedCb(INTERACTIONS.APP_MANAGER.INIT_SEGMENTS);
    await init(metaSiteInstance, patchLeftMenuCb);
    interactionEndedCb(INTERACTIONS.APP_MANAGER.INIT_SEGMENTS);
  },
);
