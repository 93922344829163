import { useMemo, useState, useCallback } from 'react';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { fetchSetupStepsData } from '../../../services/dealer/dealer.setup';
import {
  fetchLatestEventsForOffers,
  reportEventForOffer,
  EventType,
} from '../../../services/dealer/dealer.events';
import { SiteSetup } from '../../../services/setup/SiteSetup';
import type { SetupStepDealerData } from '../../../services/dealer/dealer.types';
import type { SetupStepDealerDataWithSkipFlag } from '../../../services/setup/types';
import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import { useRequest } from '../../../hooks/useRequest';
import { INTERACTIONS } from '../../../constants/constants';

export const useSiteSetup = (
  metaSiteInstance: string,
  checkPermissions: (permissions: string[]) => boolean,
  openBusinessManager: (appDefinitionId: string, path: string) => Promise<void>,
): [SiteSetup, { isLoading: boolean; error: AmbassadorHTTPError<any> }] => {
  const [userSkipEvents, setUserSkipEvents] = useState<
    Record<string, EventType>
  >({});

  const [{ stepsData, serverSkipEvents }, { retry, ...meta }] = useRequest(
    async () => {
      const setupStepsData = await fetchSetupStepsData(metaSiteInstance);
      const latestEvents = await fetchLatestEventsForOffers(
        metaSiteInstance,
        setupStepsData.map((d) => d.metadata.offerId),
        [EventType.SKIP, EventType.UNSKIP],
      );

      return {
        stepsData: setupStepsData,
        serverSkipEvents: latestEvents,
      } as any;
    },
    {
      fedopsInteractionName: INTERACTIONS.APP_MANAGER.FETCH_SETUP_STEPS,
      initialData: { stepsData: [], serverSkipEvents: {} },
    },
  );

  const setSkipped = useCallback(
    (offerId: string, isSkipped: boolean) => {
      const eventType = isSkipped ? EventType.SKIP : EventType.UNSKIP;
      const original = userSkipEvents[offerId];
      setUserSkipEvents((current) => ({
        ...current,
        [offerId]: eventType,
      }));
      void reportEventForOffer(metaSiteInstance, offerId, eventType).catch(
        (err) => {
          ErrorReporter.captureException(err, {
            tags: { failedToReportDealerEvent: true },
          });
          setUserSkipEvents((current) => {
            const copy = { ...current };
            if (original) {
              copy[offerId] = original;
            } else {
              // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
              delete copy[offerId];
            }
            return copy;
          });
        },
      );
    },
    [metaSiteInstance, userSkipEvents, setUserSkipEvents],
  );

  const openBusinessManagerAndRefreshOnClose = useCallback(
    (appDefId: string, path: string) => {
      openBusinessManager(appDefId, path).then(
        // reload the data to see if any of the steps have been completed in BM
        () => retry(),
        (err) =>
          ErrorReporter.captureException(err, {
            tags: { failedToOpenBusinessManager: true },
          }),
      );
    },
    [openBusinessManager, retry],
  );

  const siteSetup = useMemo(() => {
    return new SiteSetup(
      enrichStepsDataWithSkipFlags(stepsData, {
        ...serverSkipEvents,
        ...userSkipEvents,
      }),
      {
        checkPermissions,
        openBusinessManager: openBusinessManagerAndRefreshOnClose,
        setSkipped,
      },
    );
  }, [
    stepsData,
    serverSkipEvents,
    userSkipEvents,
    checkPermissions,
    openBusinessManagerAndRefreshOnClose,
    setSkipped,
  ]);

  return [siteSetup, meta as any];
};

function enrichStepsDataWithSkipFlags(
  stepsData: SetupStepDealerData[],
  latestEvents: Record<string, EventType>,
): SetupStepDealerDataWithSkipFlag[] {
  return stepsData.map((stepData) => ({
    ...stepData,
    isSkipped: latestEvents[stepData.metadata.offerId] === EventType.SKIP,
  }));
}
