import React from 'react';
import {
  TableListItem,
  Box,
  Text,
  Image,
  IconButton,
} from '@wix/design-system';
import { ChevronRight } from '@wix/wix-ui-icons-common';
import { getCustomActionIconByName } from '../../../utils/icons';
import styles from './BusinessAppActionItem.scss';

const getActionIcon = (iconUrl: string | undefined, iconName: string) => {
  if (iconUrl) {
    return <Image width="24px" height="24px" src={iconUrl} transparent />;
  }

  const ActionIcon = getCustomActionIconByName(iconName);

  if (ActionIcon) {
    return <ActionIcon width={24} height={24} />;
  }

  return null;
};

export interface BusinessAppActionItemProps {
  title: string;
  iconName: string;
  iconUrl?: string;
  dataHook: string;
  textDataHook: string;
  showDivider: boolean;
  onClick: () => void;
}

export const BusinessAppActionItem: React.FC<BusinessAppActionItemProps> = ({
  title,
  iconName,
  iconUrl,
  dataHook,
  textDataHook,
  showDivider,
  onClick,
}) => (
  <TableListItem
    dataHook={dataHook}
    verticalPadding="medium"
    showDivider={showDivider}
    onClick={onClick}
    options={[
      {
        value: (
          <Box verticalAlign="middle" gap="SP2">
            <Box minWidth="24px">{getActionIcon(iconUrl, iconName)}</Box>
            <div className={styles.title} data-hook={textDataHook}>
              <Text size="small" weight="thin" secondary ellipsis>
                {title}
              </Text>
            </div>
          </Box>
        ),
        width: 'auto',
        align: 'left',
      },
      {
        value: (
          <IconButton priority="secondary" size="tiny">
            <ChevronRight />
          </IconButton>
        ),
        align: 'right',
      },
    ]}
  />
);
