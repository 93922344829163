import { V2App } from '@wix/ambassador-app-service-webapp/http';
import {
  AppData,
  AppDefinitionId,
  EditorClientSpecMapEntry,
} from '@wix/document-services-types';
import {
  isDescriptorExists,
  getAppActions as getBusinessAppActions,
  getAppPremiumBannerInfo as getBusinessAppPremiumBannerInfo,
} from './services/platform/platform';
import {
  HELP_ARTICLE_IDS,
  LITE_MODE_FILTERED_APPS,
} from './constants/constants';
import {
  DealerSegmentApp,
  loadSegmentApps,
  transformSegmentApps,
} from './services/dealer/dealer.segmentApps';
import {
  loadDealerAppsData,
  transformDealerAppsData,
} from './services/dealer/dealer.manageApp';
import { DealerAppPageData } from './services/dealer/dealer.types';
import * as appMarketUtils from '../../apis/utils/appMarketUtils';
import {
  AppPremiumBannerInfo,
  AppActions,
} from './services/platform/platform.types';
import {
  compareAppsByAppManagerPredefinedList,
  getAppData,
} from './services/appMarket/appMarket.utils';
import {
  AppMarketAddAppParams,
  RegularAppData,
} from './services/appMarket/appMarket.types';
import { AppManagerApiDependencies } from '../../types/appManager';

export interface AppManagerPrivateAPI {
  metaSiteInstance: string;
  hasInstalledApps: boolean;
  availableAppsToUpdate: string[];
  supportedApps: string[];
  isClassicEditor?: boolean;

  addApp: (params: AppMarketAddAppParams) => void;
  openAppMarket: (openMarketOrigin: string) => void;
  openHelpCenter: (helpId?: string, props?: object) => void;
  loadApps: () => Promise<V2App[]>;
  getAppActions: (appDefId: string) => AppActions;
  checkPermissions: (permissions: string[]) => boolean;
  checkIsDescriptorExists: (appDefId: string) => boolean;
  loadAndTransformSegmentApps: () => Promise<DealerSegmentApp[]>;
  loadAndTransformAppPageOverrides: () => Promise<
    Record<string, DealerAppPageData>
  >;
  openBusinessManager: (appDefId: string, path: string) => Promise<void>;
  getAppPremiumBannerInfo: (appDefId: string) => AppPremiumBannerInfo | null;
}

export type OpenSettings = ({ path }: { path: string }) => void;

export function getAppManagerPrivateAPI(
  appsList: AppDefinitionId[],
  pendingAppDefIds: Record<string, boolean>,
  userPermissions: Set<string>,
  allAvailableToUpdateApps: AppData[],
  apiDependencies: AppManagerApiDependencies,
): AppManagerPrivateAPI {
  const installedBlocksApps = apiDependencies
    .getInstalledEditorApps()
    .reduce((acc: string[], app) => {
      if (
        appMarketUtils.isBuilderType(app as EditorClientSpecMapEntry) &&
        !appsList.includes(app.appDefinitionId)
      ) {
        acc.push(app.appDefinitionId!);
      }
      return acc;
    }, []);

  const supportedApps = appsList.concat(installedBlocksApps);

  const installedEditorAppsIds = apiDependencies
    .getInstalledEditorApps()
    .map((app) => app.appDefinitionId);

  const hasInstalledApps = apiDependencies
    .getInstalledEditorApps()
    .some((app) => appsList.includes(app.appDefinitionId));

  const loadAndTransformSegmentApps = async () => {
    const apps = await loadSegmentApps(apiDependencies.metaSiteInstance);

    return transformSegmentApps(apps, apiDependencies);
  };

  const loadAndTransformAppPageOverrides = async () => {
    const assets = await loadDealerAppsData(apiDependencies.metaSiteInstance);
    return transformDealerAppsData(assets, apiDependencies);
  };

  const checkPermissions = (permissions: string[]) => {
    return permissions.every((p) => userPermissions.has(p));
  };

  const availableAppsToUpdate = allAvailableToUpdateApps.reduce(
    (acc, { appDefinitionId }) => {
      if (appDefinitionId && installedBlocksApps.includes(appDefinitionId)) {
        acc.push(appDefinitionId);
      }
      return acc;
    },
    [] as string[],
  );

  const openBusinessManager = (appDefId: string, url: string) =>
    apiDependencies.openBusinessManager(appDefId, url);

  const isPendingApp = (appDefId: string) => !!pendingAppDefIds[appDefId];
  const isAppInstalled = (appDefId: string) =>
    installedEditorAppsIds.includes(appDefId);
  const checkIsDescriptorExists = (appDefId: string) =>
    isDescriptorExists(appDefId, apiDependencies);

  const getAppPremiumBannerInfo = (appDefId: string) =>
    getBusinessAppPremiumBannerInfo(appDefId, apiDependencies);

  const getAppActions = (appDefId: string): AppActions =>
    getBusinessAppActions(appDefId, apiDependencies);

  const loadApps = async () => {
    let apps: V2App[] | undefined;

    if (apiDependencies.loadedApps?.length) {
      apps = apiDependencies.loadedApps;
      apiDependencies.queryWixApps();
    } else {
      apps = await apiDependencies.queryWixApps();
    }

    if (!apps) {
      return [];
    }

    return normalizeApps(apps);
  };

  const normalizeApps = async (apps: V2App[]) => {
    return apps
      .sort(compareAppsByAppManagerPredefinedList(appsList))
      .map(getAppData(appsList))
      .map((app) => {
        if (!app) {
          return null;
        }

        const shouldFilterApp =
          (apiDependencies.isLiteMode &&
            LITE_MODE_FILTERED_APPS.includes(app.id!)) ||
          !app.isPublished;

        if (shouldFilterApp) {
          return null;
        }

        return {
          ...app,
          isPending: isPendingApp(app.id!),
          isInstalled: isAppInstalled(app.id!),
          isUpdateAvailable: availableAppsToUpdate.includes(app.id!),
        };
      })
      .filter(Boolean) as RegularAppData[];
  };

  const { openAppMarket } = apiDependencies;

  const addApp = async (params: AppMarketAddAppParams) => {
    await apiDependencies.addApp(params);
    loadApps();
  };

  const openHelpCenter = (
    helpId: string = HELP_ARTICLE_IDS.MY_BUSINESS,
    props?: object,
  ) => {
    apiDependencies.openHelpCenter(helpId, {
      ...props,
      origin: 'platformAppManager',
    });
  };

  const isClassicEditor = !!apiDependencies.isClassicEditor;
  const metaSiteInstance = apiDependencies.metaSiteInstance;

  return {
    metaSiteInstance,
    hasInstalledApps,
    supportedApps,
    availableAppsToUpdate,
    isClassicEditor,
    addApp,
    openAppMarket,
    loadApps,
    getAppActions,
    openHelpCenter,
    checkPermissions,
    loadAndTransformSegmentApps,
    loadAndTransformAppPageOverrides,
    checkIsDescriptorExists,
    openBusinessManager,
    getAppPremiumBannerInfo,
  };
}
