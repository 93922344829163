import { AppManagerApiDependencies } from '../../../../types/appManager';
import {
  dealerService,
  ESTATES,
  parseResponseOffers,
  generateActionFromDealerData,
} from './dealer.common';

import type { DealerAppPageData, DealerRawAction } from './dealer.types';

interface DealerOfferAssetShape {
  appDefinitionId: string;
  headerAppImage: string;
  headerAppName: string;
  headerAppDescription: string;

  mainAction: DealerRawAction;
  mainActionIcon: string;

  secondaryAction: DealerRawAction;
  secondaryActionIcon: string;
}

const convertDealerResponse = (
  offer: DealerOfferAssetShape,
  deps: AppManagerApiDependencies,
): DealerAppPageData => {
  const { mainAction: primaryCTA, secondaryAction: secondaryCTA } = offer;
  const primaryCTACallback = primaryCTA
    ? generateActionFromDealerData(primaryCTA, deps)
    : undefined;
  const secondaryCTACallback = secondaryCTA
    ? generateActionFromDealerData(secondaryCTA, deps)
    : undefined;

  const primaryAction1 = {
    iconUrl: offer.mainActionIcon,
    onClick: primaryCTACallback,
  };

  const primaryAction2 = {
    iconUrl: offer.secondaryActionIcon,
    onClick: secondaryCTACallback,
  };

  return {
    appDefId: offer.appDefinitionId,
    imageUrl: offer.headerAppImage,
    title: offer.headerAppName,
    description: offer.headerAppDescription,

    primaryAction1,
    primaryAction2,
  };
};

export const loadDealerAppsData = async (metaSiteInstance: string) => {
  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: ESTATES.MANAGE_APPS });

  return parseResponseOffers<DealerOfferAssetShape>(response.offers);
};

export const transformDealerAppsData = (
  assets: DealerOfferAssetShape[],
  deps: AppManagerApiDependencies,
) => {
  return assets
    .map((asset) => convertDealerResponse(asset, deps))
    .reduce<Record<string, DealerAppPageData>>((acc, cur) => {
      acc[cur.appDefId] = cur;
      return acc;
    }, {});
};
