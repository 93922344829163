import React from 'react';

export const SvgErrorAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    fill="none"
    viewBox="0 0 120 120"
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#28BF9A"
        d="M44.658 96.497H9.938l-2.97-27.58 10.4 11.52.66-20.66 7.53 19.62 6.54-23.69 12.56 40.79z"
      ></path>
      <path
        fill="#192C55"
        d="M71.561 96.5l1.949-3.968A18.578 18.578 0 0190 81.962a16.915 16.915 0 0110.688 3.782L113.943 96.5H71.561z"
      ></path>
      <path
        fill="#C2E2FF"
        d="M83.558 89.997l-1.194-.587.864-.413-5.21-16-9.958-7.39 4.418-9.61-5.21-16-7.27-3-11.4 3-5.77 16 2.746 11.692-8.886 5.308-6.13 17h-6.01l-.1 6h65l.1-6h-5.99z"
      ></path>
      <path
        fill="#192C55"
        d="M117 96v1H3v-1h114zm-33.772-7l.33 1H56v-1h27.228z"
      ></path>
      <path
        fill="#116DFF"
        d="M67.263 40h-18.67l6.13-17h7l5.54 17zm5.215 16l5.54 17h-41.33l6.14-17h29.65z"
      ></path>
    </g>
  </svg>
);
