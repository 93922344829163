import {
  DealerOfferEventsService,
  type EventsSummary,
  type ListEventsSummaryRequest,
  EventType,
} from '@wix/ambassador-dealer-offer-events-service/http';
import { ESTATES } from './dealer.common';

const DEALER_OFFER_EVENTS_SERVICE_URL = '/_api/dealer-offer-events-service';

export { EventType };

export async function reportEventForOffer(
  metaSiteInstance: string,
  offerId: string,
  eventType: EventType,
) {
  const dealerService = DealerOfferEventsService(
    DEALER_OFFER_EVENTS_SERVICE_URL,
  ).DealerOfferEventsService();

  await dealerService({ Authorization: metaSiteInstance }).reportEvent({
    event: {
      offerId,
      type: eventType,
      realEstateId: ESTATES.EDITOR_SETUP,
    },
  });
}

export async function fetchLatestEventsForOffers(
  metaSiteInstance: string,
  offerIds: string[],
  eventNames: EventType[],
): Promise<Record<string, EventType | undefined>> {
  if (offerIds.length === 0) {
    return {};
  }

  const dealerService = DealerOfferEventsService(
    DEALER_OFFER_EVENTS_SERVICE_URL,
  ).DealerOfferEventsService();

  const request: ListEventsSummaryRequest = {
    offerIds,
    summaryFilter: {
      placementId: ESTATES.EDITOR_SETUP,
      // @ts-expect-error
      // this is needed because `fieldMask` is typed as `string[]`,
      // however when an array is provided, the server request fails with 500
      // HTTP status code
      fieldMask: eventNames.map((name) => `lastEvent.${name}`).join(','),
    },
  };

  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listEventsSummary(request);

  const eventSummaries = response.eventsSummary ?? [];
  const result: Record<string, EventType | undefined> = {};
  for (const eventSummary of eventSummaries) {
    if (eventSummary.offerId) {
      result[eventSummary.offerId] = extractEventName(eventSummary.lastEvent);
    }
  }

  return result;
}

function extractEventName(
  lastEvent: EventsSummary['lastEvent'],
): EventType | undefined {
  if (!lastEvent) {
    return;
  }

  const events = Object.values(lastEvent).sort((a, b) => {
    const createdAtA = a.createdAt || new Date(0);
    const createdAtB = b.createdAt || new Date(0);
    return new Date(createdAtB).getTime() - new Date(createdAtA).getTime();
  });
  return events[0]?.type as EventType;
}
