import * as jwtUtils from '../jwt';

interface AppMarketJWTData {
  appDefId: string;
  version: string;
}

export const parseAppMarketQueryParam = (
  jwt: string,
): AppMarketJWTData | null => {
  try {
    const jwtPayload = jwtUtils.parseJwt<{ data?: string }>(jwt);
    const parsedData = jwtPayload?.data && JSON.parse(jwtPayload.data);

    return {
      appDefId: parsedData.appDefId || parsedData.appId,
      version: parsedData.version,
    };
  } catch (e) {
    return null;
  }
};
