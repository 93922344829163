import React from 'react';
import {
  Add,
  Settings,
  AppMarket,
  Design,
  Hint,
  Email,
  LayoutGalleryWithPanel,
  NewFolder,
  Adjust,
  IconProps,
  Article,
} from '@wix/wix-ui-icons-common';
/*
  mapped base-ui iconNames to wix-ui-icons-common components
  for all customAction in appManager
*/
const getCustomActionIconByName = (
  iconName: string,
): React.FC<IconProps> | null => {
  const iconsMapping: Record<string, React.FC> = {
    appManager_settingsAction: Settings,
    manageFaq: Settings,
    settingsAction: Settings,
    variations: Settings,
    appManager_addElementsAction: Add,
    addQuestionFaq: Add,
    addAction: Add,
    relatedAppsAction: Add,
    appManager_relatedAppsAction: Add,
    market: AppMarket,
    customizeDesignButtonBrush: Design,
    designTabFaq: Design,
    'sparkling-bulb-reg': Hint,
    lineIcon_email: Email,
    email_icon: Email,
    'base-composition': LayoutGalleryWithPanel,
    file_share_folder_open: NewFolder,
    file_share_adjustments: Adjust,
    settinsFaq: Adjust,
    appManager_pagesAction: Article,
  };

  if (!iconsMapping[iconName]) {
    console.warn(
      `Cannot find '${iconName}' iconName in customActionIcons mapping. Used 'Settings' icon as a fallback.`,
    );
    return Settings;
  }

  return iconsMapping[iconName];
};
export { getCustomActionIconByName };
