import React from 'react';

export const SvgPendingAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    fill="none"
    viewBox="0 0 120 120"
  >
    <g>
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#C2E2FF" d="M101 91H6V29.024L101 29v62z"></path>
        <path fill="#116DFF" d="M30 30H6v-9h27l-3 9z"></path>
        <path fill="#28BF9A" d="M46 30h55v-9H46v9z"></path>
        <path fill="#C2E2FF" d="M28 31h24V21H28v10z"></path>
        <path
          fill="#192C55"
          d="M62 47v1H16v-1h46zm-28-6v1H16v-1h18zm-.5-17a1.5 1.5 0 01.145 2.993L33.5 27a1.5 1.5 0 110-3z"
        ></path>
        <path
          fill="#116DFF"
          d="M108 73a6 6 0 016 6v21a6 6 0 01-6 6H87a6 6 0 01-6-6V79a6 6 0 016-6h21zm-91.5 5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm7.67 1.958l.006 1-3.086.017-.006-1 3.087-.017zm6.175-.033l.006 1-3.087.017-.006-1 3.087-.017zm6.174-.034l.006 1-3.087.017-.006-1 3.087-.017zm6.174-.033l.006 1-3.087.017-.006-1 3.087-.017zm6.173-.033l.006 1-3.087.017-.005-1 3.086-.017zm6.175-.033l.006 1-3.087.017-.006-1 3.087-.017zm6.173-.034l.005 1-3.086.017-.006-1 3.087-.017zm6.174-.033l.006 1-3.087.017-.006-1 3.087-.017zm6.175-.033l.006 1-3.088.017-.005-1 3.087-.017zm6.173-.034l.006 1-3.087.017-.006-1 3.087-.017zM17 73.286v2.857h-1v-2.857h1zm0-5.714v2.857h-1v-2.857h1zm74-.172v2.8h-1v-2.8h1zm-74-5.543v2.857h-1v-2.857h1zm74-.057v2.8h-1v-2.8h1zm-.5-6.8a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-74 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm38.543 2v1h-3.087v-1h3.087zm-6.173 0v1h-3.087v-1h3.087zm12.347 0v1H58.13v-1h3.087zm18.522 0v1h-3.087v-1h3.087zm6.174 0v1h-3.087v-1h3.087zm-12.348 0v1h-3.087v-1h3.087zm-6.174 0v1h-3.087v-1h3.087zm-24.695 0v1h-3.087v-1h3.087zm-18.522 0v1h-3.087v-1h3.087zm12.347 0v1h-3.087v-1h3.087zm-6.173 0v1h-3.087v-1h3.087z"
        ></path>
        <path
          fill="#192C55"
          d="M55.032 87.407c7.573 7.652 15.868 9.06 21.827 8.767l.64-.039c.21-.015.418-.032.622-.05l.6-.062.293-.035.569-.075.546-.083.523-.09.499-.094.473-.098.448-.1.42-.102.579-.152.512-.146.305-.093.396-.128.415-.144.419-.16.397.918-.37.144-.564.196-.451.145-.346.103-.38.109-.415.11-.447.113-.479.111-.25.055-.525.108-.552.103-.287.05-.594.093-.307.043-.633.08-.657.07a30.459 30.459 0 01-4.813.07l-.677-.053c-5.576-.5-12.247-2.716-18.447-8.981l.71-.703zM39.247 65.902L55.95 76.498l-6.529 1.46 4.967 6.948-2.314 1.574-4.937-6.896-3.573 5.62-4.317-19.302z"
        ></path>
        <path
          fill="#fff"
          d="M103.714 91c.71 0 1.286.576 1.286 1.286v3.428c0 .71-.576 1.286-1.286 1.286h-3.429c-.71 0-1.285-.576-1.285-1.286v-3.428c0-.71.575-1.286 1.285-1.286h3.429zm-9 0c.71 0 1.286.576 1.286 1.286v3.428c0 .71-.576 1.286-1.286 1.286h-3.429c-.71 0-1.285-.576-1.285-1.286v-3.428c0-.71.575-1.286 1.285-1.286h3.43zm9-9c.71 0 1.286.576 1.286 1.286v3.428c0 .71-.576 1.286-1.286 1.286h-3.429c-.71 0-1.285-.576-1.285-1.286v-3.428c0-.71.575-1.286 1.285-1.286h3.429zm-9 0c.71 0 1.286.576 1.286 1.286v3.428c0 .71-.576 1.286-1.286 1.286h-3.429c-.71 0-1.285-.576-1.285-1.286v-3.428c0-.71.575-1.286 1.285-1.286h3.43zM11.5 24a1.5 1.5 0 01.145 2.993L11.5 27a1.5 1.5 0 110-3z"
        ></path>
      </g>
    </g>
  </svg>
);
