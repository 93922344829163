import { useMemo, useCallback } from 'react';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { fetchRecommendedToolsData } from '../../../services/dealer/dealer.recommendedTools';
import { RecommendedTools } from '../../../services/recommendedTools/RecommendedTools';
import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import { useRequest } from '../../../hooks/useRequest';
import { INTERACTIONS } from '../../../constants/constants';

export const useRecommendedTools = (
  metaSiteInstance: string,
  openBusinessManager: (appDefinitionId: string, path: string) => Promise<void>,
): [
  RecommendedTools,
  { isLoading: boolean; error: AmbassadorHTTPError<any> },
] => {
  const [data, { retry, ...meta }] = useRequest(
    () => fetchRecommendedToolsData(metaSiteInstance),
    {
      fedopsInteractionName: INTERACTIONS.APP_MANAGER.FETCH_SETUP_STEPS,
      initialData: [],
    },
  );

  const openBusinessManagerAndRefreshOnClose = useCallback(
    (appDefId: string, path: string) => {
      openBusinessManager(appDefId, path).then(
        // reload the data to see if any of the recommended tools have been added in BM
        retry,
        (err) =>
          ErrorReporter.captureException(err, {
            tags: { failedToOpenBusinessManager: true },
          }),
      );
    },
    [openBusinessManager, retry],
  );

  const recommendedTools = useMemo(
    () => new RecommendedTools(data!, openBusinessManagerAndRefreshOnClose),
    [data, openBusinessManagerAndRefreshOnClose],
  );

  return [recommendedTools, meta as any];
};
