import {
  PRICING_PLANS,
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_RESTAURANTS_ORDERS,
  WIX_PORTFOLIO,
  WIX_NEW_STORES,
  WIX_LOYALTY_PROGRAM,
  WIX_REFERRAL_PROGRAM,
} from '@wix/app-definition-ids';

export const TRANSLATIONS = {
  PANEL_TITLE: 'App_Manager_Panel_Title',
  APP_PAGE: {
    INSTALLED: 'App_Manager_Installed',
    REMOVE: 'App_Manager_Vertical_Panel_Delete_App_Link',
    BACK_BUTTON: 'App_Manager_Panel_Back_Button',
    MORE_ACTIONS_SECTION: {
      TITLE: 'App_Manager_Vertical_Panel_Secton_Header2',
      ACTIONS_LABELS: {
        LEARN_MORE: 'App_Manager_Vertical_Panel_Learn_More_Link',
        UPDATE: 'App_Manager_Panel_Manage_update_cta',
        DELETE_APP: 'App_Manager_Vertical_Panel_Delete_App_Link',
      },
    },
  },
  ERROR_STATE: {
    GENERAL: {
      TITLE: 'App_Manager_General_Error_Message_1_Title',
      TEXT: 'App_Manager_General_Error_Message_1_Text',
    },
    CONNECTION_ISSUES: {
      TITLE: 'App_Manager_General_Error_Message_3_Title',
      TEXT: 'App_Manager_General_Error_Message_3_Text',
    },
    LINK: 'App_Manager_General_Error_Message_1_Link',
  },
  MY_BUSINESS: {
    SIDEBAR: {
      RECOMMENDED_APPS_TAB:
        'App_Manager_Ecomm_Segment_Navigation_Section2_Link',
      MORE_APPS_TAB: 'App_Manager_Tab_No_Apps_Title',
    },
    HEADERS: {
      MANAGE: 'App_Manager_Ecomm_Segment_Main_Panel_Section2_Header',
      RECOMMENDED_TOOLS: 'App_Manager_Ecomm_Segment_Main_Panel_Section3_Header',
    },
    BUSINESS_APP: {
      BTN_UNDO: 'App_Manager_Ecomm_Segment_Undo_Button',
      BTN_SKIP: 'App_Manager_Ecomm_Segment_Skip_Button',
      LINK: 'App_Manager_Ecomm_Segment_Skipped_Text',
    },
    PENDING_APP: {
      HEADER: 'App_Manager_Finalize_Installation_Header',
      TEXT: 'App_Manager_Finalize_Installation_Text',
      LINK: 'App_Manager_Finalize_Installation_Link',
    },
    SETUP_STEP: {
      PERMISSION_TEXT:
        'App_Manager_Ecomm_Segment_Tooltip_Item_Disabled_User_Permission_Text',
      COMPLETE_HEADER:
        'App_Manager_Ecomm_Segment_Main_Panel_Setup_Complete_Success_Message_Header',
      COMPLETE_TEXT:
        'App_Manager_Ecomm_Segment_Main_Panel_Setup_Complete_Success_Message_Text',
      COMPLETE_CTA:
        'App_Manager_Ecomm_Segment_Main_Panel_Setup_Complete_Success_Message_CTA',
    },
    SETUP_HEADER: {
      [WIX_NEW_STORES]: {
        TITLE: 'App_Manager_Wix_Stores_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Stores_Tab_Subeheader',
      },
      [WIX_BLOG]: {
        TITLE: 'App_Manager_Wix_Blog_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Blog_Tab_Subheader',
      },
      [WIX_BOOKINGS]: {
        TITLE: 'App_Manager_Wix_Bookings_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Bookings_Tab_Subheader',
      },
      [WIX_EVENTS]: {
        TITLE: 'App_Manager_Wix_Events_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Events_Tab_Subheader',
      },
      [WIX_RESTAURANTS_ORDERS]: {
        TITLE: 'App_Manager_Wix_Restaurants_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Restaurants_Tab_Subeheader',
      },
      [PRICING_PLANS]: {
        TITLE: 'App_Manager_Wix_Pricing_Plans_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Pricing_Plans_Tab_Subheader',
      },
      [WIX_PORTFOLIO]: {
        TITLE: 'App_Manager_Wix_Portfolio_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Portfolio_Tab_Subheader',
      },
      [WIX_LOYALTY_PROGRAM]: {
        TITLE: 'App_Manager_Wix_Loyalty_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Loyalty_Tab_Subheader',
      },
      [WIX_REFERRAL_PROGRAM]: {
        TITLE: 'App_Manager_Wix_Referral_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Referral_Tab_Subheader',
      },
    },
    MORE_APPS: {
      SECTION_TITLE: 'App_Manager_Panel_No_Apps_Title',
      APP_MARKET_LINK: 'App_Manager_Panel_App_Market_Banner_Link',
    },
  },
};
