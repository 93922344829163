import React, { useMemo, useEffect } from 'react';
import { create, FedopsLogger } from '@wix/fedops-logger';

interface EditorPlatformFedopsProviderProps {
  loggerName?: string;
}

export interface IEditorPlatformFedopsContextValue {
  fedopsLogger: FedopsLogger;
}

export function calcEditorPlatformFedopsContextValue(
  fedopsLogger: FedopsLogger,
): IEditorPlatformFedopsContextValue {
  return {
    fedopsLogger,
  };
}

export const EditorPlatformFedopsContext =
  React.createContext<IEditorPlatformFedopsContextValue>(
    {} as IEditorPlatformFedopsContextValue,
  );

export const EditorPlatformFedopsProvider: React.FC<
  React.PropsWithChildren<EditorPlatformFedopsProviderProps>
> = ({ children, loggerName = 'editor-platform-host-integration' }) => {
  const fedopsLogger = useMemo(() => {
    return create(loggerName);
  }, [loggerName]);

  useEffect(() => {
    fedopsLogger.appLoadStarted();
  }, [fedopsLogger]);

  const EditorPlatformFedops = useMemo(
    () => calcEditorPlatformFedopsContextValue(fedopsLogger),
    [fedopsLogger],
  );

  return (
    <EditorPlatformFedopsContext.Provider value={EditorPlatformFedops}>
      {children}
    </EditorPlatformFedopsContext.Provider>
  );
};
