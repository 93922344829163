import React, { FC, useState } from 'react';
import { EditorPlatformI18nProvider } from '../providers/EditorPlatformI18nProvider';
import { WixDesignSystemProvider } from '@wix/design-system';
import {
  DeleteAppModalContainer,
  DeleteAppModalProps,
} from './DeleteAppModalContainer/DeleteAppModalContainer';
import { DeleteAppOriginSource } from './DeleteAppModalContainer/constants';

export const DeleteAppModal: FC<DeleteAppModalProps> = (
  props: DeleteAppModalProps,
) => {
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <EditorPlatformI18nProvider>
        <DeleteAppModalContainer {...props} />
      </EditorPlatformI18nProvider>
    </WixDesignSystemProvider>
  );
};

export { DeleteAppOriginSource };
