import React from 'react';
import {
  TableListItem,
  Text,
  Image,
  IconButton,
  PopoverMenu,
  Box,
} from '@wix/design-system';
import { More } from '@wix/wix-ui-icons-common';
import { AUTOMATION_IDS } from '../../../constants/automationIds';
import type { AppAction } from '../../../services/platform/platform.types';
import styles from './BusinessAppHeader.scss';

export interface BusinessAppHeaderProps {
  title: string;
  description: string;
  imageSrc: string;
  generalAppActions: AppAction[];
  showDivider: boolean;
  onContextMenuOpen: () => void;
  onContextMenuActionClick: (action: AppAction) => void;
}

export const BusinessAppHeader: React.FC<BusinessAppHeaderProps> = ({
  title,
  description,
  imageSrc,
  generalAppActions,
  showDivider,
  onContextMenuOpen,
  onContextMenuActionClick,
}) => {
  return (
    <TableListItem
      dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_HEADER}
      verticalPadding="large"
      horizontalPadding="large"
      showDivider={showDivider}
      options={[
        {
          value: (
            <Box verticalAlign="middle" gap="SP2">
              <Box minWidth="36px">
                <Image
                  width="36px"
                  height="36px"
                  src={imageSrc}
                  alt={title}
                  transparent
                />
              </Box>

              <div className={styles.description}>
                <Text
                  dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_HEADER_TITLE}
                  size="medium"
                  weight="normal"
                >
                  {title}
                </Text>
                <Text
                  dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_HEADER_DESCRIPTION}
                  size="small"
                  weight="thin"
                  secondary
                  ellipsis
                >
                  {description}
                </Text>
              </div>
            </Box>
          ),
          width: 'auto',
          align: 'left',
        },
        {
          value: (
            <PopoverMenu
              showArrow
              placement="right"
              textSize="medium"
              minWidth={264}
              zIndex={1200}
              onShow={onContextMenuOpen}
              triggerElement={
                <IconButton priority="secondary" size="tiny">
                  <More />
                </IconButton>
              }
            >
              {generalAppActions.map((action) => {
                return (
                  <PopoverMenu.MenuItem
                    key={action.id}
                    text={action.title}
                    prefixIcon={action?.symbol ? <action.symbol /> : null}
                    onClick={() => onContextMenuActionClick(action)}
                  />
                );
              })}
            </PopoverMenu>
          ),
          align: 'right',
        },
      ]}
    />
  );
};
