import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { FloatingNotification, SidePanel } from '@wix/design-system';
import type { RecommendedTool } from '../../../services/recommendedTools/RecommendedTools';
import {
  RecommendedTools,
  BusinessAppSetupHeader,
  BusinessAppHeader,
  BusinessAppActionItem,
  AppSetupStep,
  Section,
  SetupSuccessState,
} from '../../components';
import {
  AUTOMATION_IDS,
  getBusinessAppDataHook,
} from '../../../constants/automationIds';
import { SETUP_SUCCESS_ANIMATION_DURATION } from '../../../constants/constants';
import { BI_EVENTS_IDS } from '../../../constants/biEvents';
import { usePrevious } from '../../../hooks/usePrevious';

import type { RegularAppData } from '../../../services/appMarket/appMarket.types';
import type { AppSetup } from '../../../services/setup/AppSetup';
import type {
  AppAction,
  AppActions,
  AppPremiumBannerInfo,
} from '../../../services/platform/platform.types';

import type {
  DealerAppPageAction,
  DealerAppPageData,
} from '../../../services/dealer/dealer.types';
import { useBILogger } from '@wix/fe-essentials/react';
import { TRANSLATIONS } from '../../../constants/translations';
import styles from './businessApp.scss';
import { t } from '../../../../../essentials/i18n';
import {
  myBusinessActionsOnTab,
  myBusinessAllSetUpActionsAreCompleted,
} from '@wix/bi-logger-editor-x/v2';

export interface BusinessAppProps {
  app: RegularAppData;
  appSetup: AppSetup;
  recommendedTools: RecommendedTool[];
  dealerData?: DealerAppPageData;
  appActions: AppActions;
  premiumBannerInfo: AppPremiumBannerInfo | null;
}

export const BusinessApp: React.FC<BusinessAppProps> = ({
  app,
  appSetup,
  recommendedTools,
  appActions,
  premiumBannerInfo,
  dealerData,
}) => {
  const logger = useBILogger();

  const manageActions = useMemo(() => {
    const { primary, secondary } = appActions;
    const primaryWithOverrides =
      (dealerData?.primaryAction1 &&
        dealerData?.primaryAction2 &&
        mergePrimaryActions(
          primary,
          dealerData?.primaryAction1,
          dealerData?.primaryAction2,
        )) ||
      [];
    return primaryWithOverrides.concat(secondary);
  }, [appActions, dealerData]);

  const [isAppSetupComplete, setIsAppSetupComplete] = useState(
    appSetup.isComplete,
  );

  const dismissAppSetupSuccessState = useCallback(
    () => setIsAppSetupComplete(true),
    [],
  );

  const onSuccessAnimationAppeared = useCallback(() => {
    setTimeout(dismissAppSetupSuccessState, SETUP_SUCCESS_ANIMATION_DURATION);
  }, [dismissAppSetupSuccessState]);

  const onHeaderContextMenuOpen = useCallback(() => {
    logger.log({
      evid: BI_EVENTS_IDS.APP_ACTION_CLICKED,
      tab_name: app.id,
      action_type: 'general',
      action_id: 'three dots menu',
    });
    logger.report(
      myBusinessActionsOnTab({
        tabName: app.id,
        actionType: 'general',
        actionName: 'three dots menu',
      }),
    );
  }, [logger, app]);

  const onHeaderContextMenuActionClick = useCallback(
    (action: any) => {
      action.onClick();
      logger.log({
        evid: BI_EVENTS_IDS.APP_ACTION_CLICKED,
        tab_name: app.id,
        action_type: 'general',
        action_id: action.id,
      });
      logger.report(
        myBusinessActionsOnTab({
          tabName: app.id,
          actionType: 'general',
          actionName: action.id,
        }),
      );
    },
    [logger, app],
  );

  const onPremiumBannerUpgradeClick = useCallback(() => {
    premiumBannerInfo?.upgrade();
    logger.log({
      evid: BI_EVENTS_IDS.APP_ACTION_CLICKED,
      tab_name: app.id,
      action_type: 'general',
      action_id: 'upgrade',
    });
    logger.report(
      myBusinessActionsOnTab({
        tabName: app.id,
        actionType: 'general',
        actionName: 'upgrade',
      }),
    );
  }, [premiumBannerInfo, logger, app]);

  const onManageActionClick = useCallback(
    (action: any) => {
      action.onClick();
      logger.log({
        evid: BI_EVENTS_IDS.APP_ACTION_CLICKED,
        tab_name: app.id,
        action_type: 'quick',
        action_id: action.id,
      });
      logger.report(
        myBusinessActionsOnTab({
          tabName: app.id,
          actionType: 'quick',
          actionName: action.id,
        }),
      );
    },
    [logger, app],
  );

  const nextSetupStep = appSetup.nextStep;
  const prevIsComplete = usePrevious(appSetup.isComplete);

  useEffect(() => {
    if (appSetup.isComplete && appSetup.isComplete !== prevIsComplete) {
      logger.log({
        evid: BI_EVENTS_IDS.SETUP_COMPLETED,
        app_id: app.id,
      });
      logger.report(
        myBusinessAllSetUpActionsAreCompleted({
          appId: app.id,
        }),
      );

      onSuccessAnimationAppeared();
    }
  }, [app, appSetup, logger, prevIsComplete]);

  const appHeaderSection = (
    <div data-hook={getBusinessAppDataHook(app.id)}>
      <BusinessAppHeader
        title={dealerData?.title || app.name}
        description={dealerData?.description || app.description}
        imageSrc={dealerData?.imageUrl || app.icon}
        generalAppActions={appActions.general}
        showDivider={!premiumBannerInfo}
        onContextMenuOpen={onHeaderContextMenuOpen}
        onContextMenuActionClick={onHeaderContextMenuActionClick}
      />

      {premiumBannerInfo && (
        <FloatingNotification
          type="premium"
          text={premiumBannerInfo.text}
          textButtonProps={{
            label: premiumBannerInfo.linkText,
            onClick: onPremiumBannerUpgradeClick,
          }}
          showCloseButton={false}
          fullWidth
        />
      )}
    </div>
  );

  const appSetupSection = !isAppSetupComplete ? (
    <>
      <Section
        heading={
          <BusinessAppSetupHeader
            appDefId={app.id}
            appName={app.name}
            stepsCompleted={appSetup.completedStepsCount}
            stepsTotal={appSetup.totalStepsCount}
            showProgress={!appSetup.isComplete}
          />
        }
      >
        <div className={appSetup.isComplete ? styles.hidden : ''}>
          {appSetup.steps.map((step, index) => (
            <AppSetupStep
              key={step.id}
              step={step}
              isPriority={step === nextSetupStep}
              order={index}
              showDivider={index !== appSetup.steps.length - 1}
            />
          ))}
        </div>

        <div className={!appSetup.isComplete ? styles.hidden : ''}>
          <SetupSuccessState
            appName={app.name}
            dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.SUCCESS_ANIMATION}
            onDismiss={dismissAppSetupSuccessState}
          />
        </div>
      </Section>

      <SidePanel.Divider />
    </>
  ) : null;

  const manageActionsSection = manageActions.length ? (
    <Section heading={t(TRANSLATIONS.MY_BUSINESS.HEADERS.MANAGE)}>
      {manageActions.map((action, index) => (
        <BusinessAppActionItem
          key={action.id}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_MANAGE_ACTION}
          textDataHook={action.dataHook}
          title={action.title}
          iconName={action.iconName || ''}
          iconUrl={action.iconUrl}
          showDivider={index !== manageActions.length - 1}
          onClick={() => onManageActionClick(action)}
        />
      ))}
    </Section>
  ) : null;

  const recommendedToolSection = recommendedTools.length ? (
    <>
      <SidePanel.Divider />

      <RecommendedTools tools={recommendedTools} />
    </>
  ) : null;

  return (
    <>
      <SidePanel.Content stretchVertically={false} noPadding>
        {appHeaderSection}
      </SidePanel.Content>

      <SidePanel.Content noPadding>
        {appSetupSection}

        {manageActionsSection}

        {recommendedToolSection}
      </SidePanel.Content>
    </>
  );
};

interface AppActionWithOverrides extends AppAction {
  iconUrl?: string;
}

function mergeDefaultAndDealerAction(
  defaultAction: AppAction,
  dealerAction: DealerAppPageAction,
): AppActionWithOverrides {
  if (!dealerAction) {
    return defaultAction;
  }

  return {
    id: defaultAction.id,
    iconName: defaultAction.iconName,
    iconUrl: dealerAction?.iconUrl,
    title: defaultAction.title,
    onClick: dealerAction.onClick || defaultAction.onClick,
    dataHook: defaultAction.dataHook,
  };
}

function mergePrimaryActions(
  rawActions: AppAction[],
  dealerAction1: DealerAppPageAction,
  dealerAction2: DealerAppPageAction,
): AppActionWithOverrides[] {
  return [
    mergeDefaultAndDealerAction(rawActions[0], dealerAction1),
    mergeDefaultAndDealerAction(rawActions[1], dealerAction2),
  ].filter(Boolean);
}
